import axios from "axios"
import { BASE_URL, APP_USERS_SERVER } from "../../../components/utils/constants/misc"
import { 
    // SET_USER_PASSWORD,
    ADMIN_CREATE_NEW_APP_USER,
    FETCH_APP_USERS,
    CLEAR_APP_USERS,
    DELETE_APP_USER,
    DELETE_APP_USER_FROM_APP_USER_LIST_TBL,
    ADD_UPDATE_APP_USER_IN_APP_USER_LIST_TBL,
    UPDATE_APP_USER
} from '../../actions/types';


export const adminCreateNewAppUser = (values) => async dispatch => {
    // console.log('values in adminCreateNewAppUser')
    // console.log('values in adminCreateNewAppUser')
    // console.log(values)
    const res = await axios.post(`${BASE_URL}${APP_USERS_SERVER}/create-new-app-user`, values);
    dispatch({ type: ADMIN_CREATE_NEW_APP_USER, payload: res.data });
    return Promise.resolve(res);
}

export const updateAppUser = (values) => async dispatch => {
    // console.log('values in updateAppUser')
    // console.log('values in updateAppUser')
    // console.log(values)
    const res = await axios.post(`${BASE_URL}${APP_USERS_SERVER}/update-app-user`, values);
    dispatch({ type: UPDATE_APP_USER, payload: res.data });
    return Promise.resolve(res);
}

export const fetchAppUsers = (values) => async dispatch => {
    const res = await axios.post(`${BASE_URL}${APP_USERS_SERVER}/fetch-app-users`,values);
    dispatch({ type: FETCH_APP_USERS, payload: res.data });
    return Promise.resolve(res);
};


export const clearAppUsers = () => async dispatch => {
    dispatch({ type: CLEAR_APP_USERS, payload: {objAppUsers: [],total_app_users:0, error: false} });
};

export const deleteAppUser = (user_id) => async dispatch => {
    const res = await axios.delete(`${BASE_URL}${APP_USERS_SERVER}/delete-app-user/${user_id}`);
    dispatch({ type: DELETE_APP_USER, payload: res.data });
    return Promise.resolve(res);
};

export const deleteAppUserFromAppUserListTbl = (user_id) => async dispatch => {
    dispatch({ type: DELETE_APP_USER_FROM_APP_USER_LIST_TBL, payload: user_id });
};

export const addUpdateAppUserInAppUserListTbl = (objAppUser) => async dispatch => {
    dispatch({ type: ADD_UPDATE_APP_USER_IN_APP_USER_LIST_TBL, payload: objAppUser });
};

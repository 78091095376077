// @flow
import { createStore, applyMiddleware, compose } from 'redux';
// import { combineReducers } from "redux";
import thunk from "redux-thunk"
// import sagaReducers from './rootReducer';
// import thunkReducers from "../redux/reducers/rootReducer"
import rootReducer from "../reducers/rootReducer"

const middlewares = [thunk];

function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch(e) {
        console.log(e)
    }
}

  
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  // {},
  {
    // auth: { authenticated: localStorage.getItem('token') },
  },
  composeEnhancers(applyMiddleware(...middlewares))
)
store.subscribe(() => saveToLocalStorage(store.getState()))
export { store }

import {
    ADMIN_CREATE_NEW_APP_USER,
    FETCH_APP_USERS,
    CLEAR_APP_USERS,
    DELETE_APP_USER,
    DELETE_APP_USER_FROM_APP_USER_LIST_TBL,
    UPDATE_APP_USER,
    ADD_UPDATE_APP_USER_IN_APP_USER_LIST_TBL,
} from '../../actions/types';

export const appUserAccount = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_CREATE_NEW_APP_USER: {
            return { ...state, objCreateAdminAppUserSuccess: action.payload }
        }
        case FETCH_APP_USERS: {
            // console.log('state.objAppUsers in store')
            // console.log(state.objAppUsers)
            if (state.objAppUsers === undefined) {
                return { ...state, objAppUsers: action.payload }
            } else {
                return {
                    ...state,
                    objAppUsers: {
                        ...state.objAppUsers,
                        error: action.payload.error,
                        msg: action.payload.msg,
                        total_app_users: action.payload.total_app_users,
                        // objAppUsers:action.payload.objAppUsers,
                        objAppUsers: [...state.objAppUsers.objAppUsers, ...action.payload.objAppUsers]
                    }
                }
            }
        }
        case CLEAR_APP_USERS: {
            return { ...state, objAppUsers: action.payload }
        }
        case DELETE_APP_USER: {
            return { ...state, objDeletAdminUserSuccess: action.payload }
        }
        case DELETE_APP_USER_FROM_APP_USER_LIST_TBL: {
            let user_id = action.payload;
            let objAppUsers = state.objAppUsers;
            let items = state.objAppUsers.objAppUsers.slice();
            let total_app_users = state.objAppUsers.total_app_users;
            const itemIndex = items.findIndex(item => item._id === user_id);
            console.log('itemIndex')
            console.log(itemIndex)
            console.log('total_app_users')
            console.log(total_app_users)
            // add new one
            if (itemIndex !== -1) {
                const nextState = items.filter(item => item._id !== user_id)
                console.log('nextState')
                console.log(nextState)
                total_app_users = total_app_users - 1;
                console.log('total_app_users after')
                console.log('total_app_users after')
                console.log(total_app_users)
                return {
                    ...state,
                    objAppUsers: {
                        ...objAppUsers,
                        objAppUsers: nextState,
                        total_app_users,
                    },
                }
            } else {
                return state;
            }
        }

        case UPDATE_APP_USER: {
            return { ...state, objUpdateAdminUserFormSuccess: action.payload }
        }

        case ADD_UPDATE_APP_USER_IN_APP_USER_LIST_TBL: {
            let objAppUser = action.payload;
            let objAppUsers = state.objAppUsers;
            let items = state.objAppUsers.objAppUsers.slice();
            let total_app_users = state.objAppUsers.total_app_users;
            const itemIndex = items.findIndex(item => item._id === objAppUser._id);
            console.log('itemIndex in ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
            console.log(itemIndex)
            // add new one
            if (itemIndex === -1) {
                console.log('I am in if of ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
                items.unshift(objAppUser)
                // items.push(objSignal)
                total_app_users = total_app_users + 1;
                return {
                    ...state,
                    objAppUsers: {
                        ...objAppUsers,
                        objAppUsers: items,
                        total_app_users,
                    },
                }
            } else {
                console.log('I am in else of ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
                console.log('I am in else of ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
                // update the existing one
                let objAppUsers = state.objAppUsers.objAppUsers.slice();
                const nextStateobjAppUsers = objAppUsers.map(item => {
                    console.log('item._id')
                    console.log('item._id')
                    console.log(item._id)
                    console.log('objAppUser._id')
                    console.log('objAppUser._id')
                    console.log(objAppUser._id)
                    if (item._id !== objAppUser._id) {
                        // not our item, return it as is
                        return item;
                    }
                    console.log("Item found ")
                    console.log("Item found ")
                    console.log("Item found ")
                    // this is our relevant item, return a new copy of it with modified fields
                    return {
                        // ...item,
                        ...objAppUser,
                    }
                });
                return {
                    ...state,
                    objAppUsers: {
                        ...objAppUsers,
                        objAppUsers: nextStateobjAppUsers,
                    },
                }
            }
        }
        
        
        default: {
            return state
        }
    }
}

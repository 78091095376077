import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, 
    // Card,
    
} from 'reactstrap';
import EditOrganizationModalBody from './EditOrganizationModalBody';
const objInitialValues = {
    name: "",
    price: "",
    drpFrameSize:"",
}
const EditOrganizationModal = (props) => {
    const {
        // buttonLabel,
        showEditOrganizationModal,setShowEditOrganizationModal,
        selectedEditObjOrganization
    } = props;
    const toggle = () => {
        setShowEditOrganizationModal(!showEditOrganizationModal);
    };
   
    // const btnOnClickClosePosition = () =>{
    //     toggle();
    // }

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal backdrop={false} size="lg" isOpen={showEditOrganizationModal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Edit {selectedEditObjOrganization.name}</ModalHeader>
                <ModalBody>
                    <div className='add-listings-box'>
                        <EditOrganizationModalBody 
                            objInitialValues={objInitialValues}
                            selectedEditObjOrganization={selectedEditObjOrganization}
                            toggle={toggle}
                        />
                    </div>
                    {/* <Card className="SignalsSection">
                        <CardHeader>
                            <CardTitle></CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className='add-listings-box'>
                                <EditOrganizationModalBody 
                                    selectedEditObjOrganization={selectedEditObjOrganization}
                                    toggle={toggle}
                                />
                            </div>
                        </CardBody>
                    </Card> */}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Exit</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditOrganizationModal;
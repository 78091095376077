import React, { Fragment } from "react"
import {
    Button
} from "reactstrap"
import { formatDate } from "../../../../utils/Helpers/Date/DateTimeHelpers"
const OrganizationsListTr = (props) => {
    
    return (
        <Fragment>
            <tr>
                
                <td className='details'>
                    <ul>
                        <li>
                            <i className='bx bx-map'></i>
                            <span>Name:</span>
                            {props.name}
                        </li>
                        <li>
                            <i className='bx bx-calendar'></i>
                            <span>Created Date:</span>
                            {formatDate(props.createdAt)}
                        </li>
                    </ul>

                </td>
                <td className='action'>
                    <Button className='default-btn'
                        onClick={() => {
                            props.btnOnClickEditFrame(props)
                        }}
                    >
                        <i className='bx bx-edit'></i> Edit
                    </Button>
                    <Button className='default-btn danger' color="danger"
                        onClick={() => {
                            props.btnOnClickDeleteFrame(props)
                        }}
                    >
                        <i className='bx bx-x-circle'></i> Delete
                    </Button>
                </td>
            </tr>
        </Fragment>
    )
}
export default OrganizationsListTr

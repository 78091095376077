import React, { Fragment, useState, useEffect } from "react"
import {  FormGroup, Label, Button, Row, Col } from "reactstrap"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux";
import LoadingSpinner from '../../../../../utils/Helpers/Loading/LoadingSpinner'
import { showMsg } from "../../../../../utils/general";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { updateAppUser, addUpdateAppUserInAppUserListTbl } from "../../../../../redux/actions/appUserAuth/appUserAccountActions";
const animatedComponents = makeAnimated();

const initialValues = {
    name:'',
    password:'',
    is_change_password_enabled: false,
}

const editAdminUserFormSchema = Yup.object().shape({
    name: Yup.string().required("full name is Required"),
    is_change_password_enabled: Yup.bool().oneOf([true,false], ' Check This'),
    password: Yup.string()
        .when("is_change_password_enabled", {
        is: true,
        then: () => Yup.string().min(8, "Password is too short!(min 8 characters are required)")
                .required("Password is required")
    })
})  
const EditAppUserModalBody = (props) => {
    const {selectedEditObjUser,formatedOrganizationDrpOptions} = props;
    const [objInitialValues, setObjInitialValues] = useState(initialValues);
    const dispatch = useDispatch();
    const [isEditingAppUser,setIsEditingAppUser] = useState(false);
    const [selectedObjOrganization, setSelectedObjOrganization] = useState({});
    useEffect(() => {
        let __ObjInitialValues = {
            name: selectedEditObjUser.full_name,
            password:'',
            is_change_password_enabled: false,
        };
        setObjInitialValues(__ObjInitialValues);
        const __objSelectedObjOrganization = {value: selectedEditObjUser.organization_id._id, 
            label: selectedEditObjUser.organization_id.name,
            name:selectedEditObjUser.organization_id.name,
            _id: selectedEditObjUser.organization_id._id
        };
        setSelectedObjOrganization(__objSelectedObjOrganization);
    },[selectedEditObjUser]);
    const onSubmitForm = async (values) => {
        console.log('values in submit in EditAppUserModalBody')
        console.log(values)
        let objPostData = {
            user_id:selectedEditObjUser._id,
            organization_id:selectedObjOrganization._id,
            ...values
        }
        setIsEditingAppUser(true)
        dispatch(updateAppUser(objPostData)).then( response =>{
            setIsEditingAppUser(false)
            if(response.data.error === false){
                showMsg('success',response.data.msg)
                dispatch(addUpdateAppUserInAppUserListTbl(response.data.objAppUser));
                props.toggle()
            }else{
                showMsg('error',response.data.msg)
            }
        });
        // console.log('selectedObjUser')
        // console.log(selectedObjUser)
    };

    const handleOnChangeSelect = selectedOptions => {
        console.log('selectedOptions')
        console.log('selectedOptions')
        console.log(selectedOptions)
        setSelectedObjOrganization(selectedOptions);
        
    };
    
    
    return (
        <Fragment>
            <Formik
                initialValues={objInitialValues}
                validationSchema={editAdminUserFormSchema}
                onSubmit={onSubmitForm}
                enableReinitialize={true}
            >
                {({ errors, touched }) => (
                <Form>
                    <Row>
                        <Col md="6">
                            <FormGroup className={`form-group ${errors.name && touched.name && "text-danger"}`}>
                                <Label ><i className='bx bx-user'></i> Name</Label>
                                <Field
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Name"
                                    className={`form-control ${errors.name &&
                                        touched.name &&
                                        "is-invalid"}`}
                                />
                                
                                {errors.name && touched.name ? (
                                    <div className="invalid-feedback">{errors.name}</div>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup className={`form-group ${errors.password && touched.password && "text-danger"}`}>
                                <Label for="password"><i className='bx bx-lock-alt'></i> Password</Label>
                                <Field
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    className={`form-control ${errors.password &&
                                        touched.password &&
                                        "is-invalid"}`}
                                />
                                {errors.password && touched.password ? (
                                    <div className="invalid-feedback">{errors.password}</div>
                                ) : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                                <FormGroup check className={`form-group ${errors.is_change_password_enabled && touched.is_change_password_enabled && "mb-3"} form-check-own`}>
                                    <div>
                                        <div className={`custom-checkbox custom-control `}>
                                            <Field
                                                type="checkbox"
                                                name="is_change_password_enabled"
                                                id="is_change_password_enabled"
                                                className={`form-check-input ${errors.is_change_password_enabled &&
                                                touched.is_change_password_enabled &&
                                                "is-invalid"}`}
                                            />
                                            <Label for="is_change_password_enabled"  check>Change Password</Label>
                                            {errors.is_change_password_enabled && touched.is_change_password_enabled ? (
                                                <div className="invalid-feedback">{errors.is_change_password_enabled}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup className={`form-group ${errors.organization_id && touched.organization_id && "text-danger"}`}>
                                <h6 className="text-bold-100">Select Organization</h6>
                                <Select
                                    components={animatedComponents}
                                    name="organization_id"
                                    options={formatedOrganizationDrpOptions}
                                    className="React"
                                    classNamePrefix="select"
                                    value={selectedObjOrganization}
                                    onChange={handleOnChangeSelect}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="text-end mb-1">
                        {
                            isEditingAppUser === false ? 
                                <div className="add-listings-btn">
                                    <Button color="success" type="submit" >
                                        Edit App User
                                    </Button>
                                </div>
                            :<LoadingSpinner subClass="text-center" color="lightblueColor" size="lg" />
                        }
                    </div>
                    
                
                </Form>
                )}
            </Formik>
      </Fragment>
    )
}
export default EditAppUserModalBody

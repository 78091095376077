import React, { useState,useEffect } from "react"
import {
    // Row,
    // Col,
    // Button,
    Table 
} from "reactstrap"
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { 
    clearAdminUsers, 
    fetchAdminUsers,
    deleteAdminUser,
    deleteAdminUserFromAdminUserListTbl
} from "../../../redux/actions/adminAuth/adminUserAccountActions";
import AdminUsersLoadUserList from "./components/AdminUsersLoadUserList";
import { showMsg } from "../../../utils/general";
import LoadingSpinner from '../../../utils/Helpers/Loading/LoadingSpinner'
import EditAdminUserModal from "./components/EditAdminUserModal/EditAdminUserModal";
// import "./scss/AdminUsersListPage.scss"

const AdminUsersList = (props) => {
    // console.log('props in home')
    // console.log('props in home')
    // console.log('props in home')
    // console.log(props)
    // const { objUser } = props;
    const [perPage, setPerPage] = useState(5);
    const [page, setPage] = useState(1);
    const [isLoadingAdminUsers, setIsLoadingAdminUsers] = useState(false);
    const [total_admin_users, setTotalAdminUsers] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [showEditAdminUserModal, setShowEditAdminUserModal] = useState(false);
    const [selectedEditObjUser, setSelectedEditObjUser] = useState({});

    const handlePageClick = (data) => {
        console.log('data in handlePageClick')
        console.log('data in handlePageClick')
        let selectedPage = data.selected;
        console.log(data)
        console.log('before selectedPage')
        console.log('before selectedPage')
        console.log('before selectedPage')
        console.log(selectedPage)
        selectedPage = selectedPage +1;
        console.log('selectedPage')
        console.log('selectedPage')
        console.log('selectedPage')
        console.log(selectedPage)
        setPage(selectedPage)
        loadMoreAdminUsers(selectedPage,perPage)
        // 
    }
    const dispatch = useDispatch();
    const {  objAdminUsers } = useSelector(state => ({
        objAdminUsers : state.adminAuth.adminUserAccount.objAdminUsers,
    }));

    const loadMoreAdminUsers = (page,perPage) => {
        dispatch(clearAdminUsers())
        setIsLoadingAdminUsers(true);
        dispatch(fetchAdminUsers({page,perPage})).then(async response =>{
            setIsLoadingAdminUsers(false);
            if(response.data.error === false){
                setTotalAdminUsers(response.data.total_admin_users)
                setPageCount(response.data.pageCount)
            }
        });
    }

    useEffect(() => {
        loadMoreAdminUsers(page,perPage);
        // componentWillUnmount 
        return () => {
            dispatch(clearAdminUsers())
        }
    }, []);
    const btnOnClickEditUser = (objUser) => {
        console.log('objUser in btnOnClickEditUser')
        console.log('objUser in btnOnClickEditUser')
        console.log(objUser)
        setShowEditAdminUserModal(true);
        setSelectedEditObjUser(objUser);
    };
    const btnOnClickDeleteUser = (objUser) => {
        console.log('objUser in btnOnClickDeleteUser')
        console.log('objUser in btnOnClickDeleteUser')
        console.log(objUser)
        let conf = window.confirm('Are you sure?');
        if(!conf){
            return false;
        }
        dispatch(deleteAdminUser(objUser._id)).then( response =>{
            if(response.data.error === false){
                showMsg('success',`You have deleted the user`)
                dispatch(deleteAdminUserFromAdminUserListTbl(objUser._id));
            }else{
                showMsg('error',response.data.msg)
            }
        });
        // setShowEditAdminUserModal(true);
        // setSelectedEditObjUser(objUser);
    };
    console.log('objAdminUsers in render')
    console.log('objAdminUsers in render')
    console.log('objAdminUsers in render')
    console.log('objAdminUsers in render')
    console.log(objAdminUsers)
    return (
        <div className='HomePage'>
            
            <div className='bookings-listings-box'>
                <h3>Admin Users List</h3>
                <Table>
                    <thead>
                        <tr>
                            <th>Details</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {
                        objAdminUsers !== undefined ?
                        <tbody>
                            <AdminUsersLoadUserList
                                isLoadingAdminUsers={isLoadingAdminUsers}
                                objAdminUsers={objAdminUsers}
                                total_admin_users={total_admin_users}
                                btnOnClickEditUser={btnOnClickEditUser}
                                btnOnClickDeleteUser={btnOnClickDeleteUser}
                            />
                        </tbody>
                        : null
                    }
                </Table>
                {
                    isLoadingAdminUsers ? <LoadingSpinner subClass="text-center" color="lightblueColor" size="lg"  /> :null
                }
                <ReactPaginate
                    // previousLabel={<ChevronLeft size={15} />}
                    // nextLabel={<ChevronRight size={15} />}
                    previousLabel={<i className="bx bx-chevrons-left"></i>}
                    nextLabel={<i className="bx bx-chevrons-right"></i>}
                    nextClassName="next page-numbers"
                    previousClassName="prev page-numbers"
                    pageClassName="page-numbers"
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    forcePage={page -1} 
                    containerClassName={
                    "pagination-area text-center mt-3 mb-3"
                    }
                    activeClassName={"current"}
                />
            </div>
            <EditAdminUserModal 
                showEditAdminUserModal={showEditAdminUserModal}
                setShowEditAdminUserModal={setShowEditAdminUserModal}
                selectedEditObjUser={selectedEditObjUser}
            />
            <div className='flex-grow-1'></div>

        </div>
    )
}
export default AdminUsersList

import { 
    FETCH_SETTINGS,
} from '../../actions/types';

export const settingsData = (state = {}, action) => {
    switch (action.type) {
        case FETCH_SETTINGS: {
            return { ...state, objFetchSettingsData: action.payload }
        }
        default: {
            return state
        }
    }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from "./redux/storeConfig/store"
import axios from 'axios';
window.axios = axios;
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
let token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = token;
} else {
  axios.defaults.headers.common['Authorization'] = null;
}

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
//   ,
//   document.getElementById('root')
// );
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { Fragment, useState, useEffect } from "react"
import {
    Row, Col, Label, FormGroup, Button,
    Input, FormText
} from 'reactstrap';
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux";
import LoadingSpinner from '../../../../../utils/Helpers/Loading/LoadingSpinner'
import { showMsg } from "../../../../../utils/general";
import { addUpdateOrganizationInOrganizationListTbl, updateOrganization } from "../../../../../redux/actions/organizations/organizationsActions";
import { useNavigate } from "react-router-dom";


const editOrganizationFormSchema = Yup.object().shape({
    name: Yup.string()
        .required("Name is Required"),
})  
const EditOrganizationModalBody = (props) => {
    const {selectedEditObjOrganization} = props;
    const [objInitialValues, setObjInitialValues] = useState(props.initialValues);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [isLoading,setIsLoading] = useState(false);
    const [isEditingOrganization,setIsEditingOrganization] = useState(false);
    
    useEffect(() => {
        let __ObjInitialValues = {
            name: selectedEditObjOrganization.name,
        };
        setObjInitialValues(__ObjInitialValues);
    },[selectedEditObjOrganization]);
    
    
    const onSubmitForm = async (values) => {
        saveOrganizationData(values)
    };

    

    const saveOrganizationData = async (values) => {
        const objPostData = { 
            ...values, 
            organization_id:selectedEditObjOrganization._id,
        }
        setIsLoading(true);
        setIsEditingOrganization(true)
        dispatch(updateOrganization(objPostData)).then(async response => {
            setIsLoading(false);
            setIsEditingOrganization(false);
            const data = response.data;
            console.log('response.data')
            console.log('response.data')
            console.log('response.data')
            console.log(data)
            if (response.data.error === false) {
                showMsg('success', response.data.msg);
                dispatch(addUpdateOrganizationInOrganizationListTbl(response.data.objOrganization));
                props.toggle();
                navigate('/organizations/list');
            } else {
                showMsg('error', response.data.msg)
            }
        });
        
    }

    
    
    return (
        <Fragment>
            <Formik
                initialValues={objInitialValues}
                validationSchema={editOrganizationFormSchema}
                onSubmit={onSubmitForm}
                enableReinitialize={true}
            >
                {({ errors, touched }) => (
                <Form>
                    <Row>
                        <Col md="12">
                            <FormGroup className={`form-group ${errors.name && touched.name && "text-danger"}`}>
                                <Label ><i className='bx bx-user'></i> Name</Label>
                                <Field
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="name"
                                    className={`form-control ${errors.name &&
                                        touched.name &&
                                        "is-invalid"}`}
                                />

                                {errors.name && touched.name ? (
                                    <div className="invalid-feedback">{errors.name}</div>
                                ) : null}
                            </FormGroup>
                        </Col>
                        
                        {/* <Col md="4" sm="4">
                            <FormGroup className={`${errors.drpFrameSize && touched.drpFrameSize && "text-danger"}`}>
                                <Label for="drpFrameSize">Select Frame Size</Label>
                                <Field
                                    as="select"
                                    name="drpFrameSize"
                                    id="drpFrameSize"
                                    className={`form-control ${errors.drpFrameSize &&
                                    touched.drpFrameSize &&
                                    "is-invalid"}`}
                                >
                                    <option value="">Select Frame Size</option>
                                    <option value="four_by_6">4*6</option>
                                    <option value="five_by_7">5*7</option>
                                </Field>
                                {errors.drpFrameSize && touched.drpFrameSize ? (
                                    <div className="invalid-feedback">{errors.drpFrameSize}</div>
                                ) : null}
                            </FormGroup>
                        </Col> */}

                    </Row>
                    <div className="text-end mb-1">
                        {
                            isEditingOrganization === false ? 
                                <div className="add-listings-btn">
                                    <Button color="success" type="submit" >
                                        Edit Organization
                                    </Button>
                                </div>
                            :<LoadingSpinner subClass="text-center" color="lightblueColor" size="lg" />
                        }
                    </div>
                    
                
                </Form>
                )}
            </Formik>
      </Fragment>
    )
}
export default EditOrganizationModalBody

import React, { Fragment } from "react";
import AppUsersListItemBlock from './AppUsersListItemBlock'
// import LoadingSpinner from "../../../../../components/helpers/LoadingSpinner"
const AppUsersLoadUserList = (props) => {
  
  return (
    <Fragment>
        <AppUsersListItemBlock
          list={props.objAppUsers.objAppUsers}
          isLoadingAppUsers={props.isLoadingAppUsers}
          btnOnClickEditUser={props.btnOnClickEditUser}
          btnOnClickDeleteUser={props.btnOnClickDeleteUser}
        />
    </Fragment>
  )
}
export default AppUsersLoadUserList

import React from "react"
import AddAdminUsersForm from "./components/AddAdminUsersForm";

const objInitialValues = {
    name: "",
    email: "",
    password: "",
}
const AddAdminUsers = (props) => {

    return (
        <div className='HomePage'>
            <div className='add-listings-box'>
                <h3>Add New Admin User</h3>
                <AddAdminUsersForm
                    objInitialValues={objInitialValues}
                />
                {/* <Row>
                    <Col md="4">
                        <div className='form-group'>
                            <label>
                                <i className='bx bx-user'></i> Name:
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Name of User'
                            />
                        </div>
                    </Col>
                    <Col md="4">
                        <div className='form-group'>
                            <label>
                                <i className='bx bx-envelope'></i> Email:
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter Frame Email'
                            />
                        </div>
                    </Col>
                    <Col md="4">
                        <div className='form-group'>
                            <label>
                                <i className='bx bx-lock-alt'></i> Password:
                            </label>
                            <input
                                type='password'
                                className='form-control'
                                placeholder='Enter User Password'
                            />
                        </div>
                    </Col>
                    
                </Row>
                
                <Row>
                    <Col md="12" className="text-end">
                        <div className="add-listings-btn">
                            <button type="submit">Create</button>
                        </div>
                    </Col>
                </Row> */}
            </div>
            <div className='flex-grow-1'></div>

        </div>
    )
}
export default AddAdminUsers

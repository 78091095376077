import React, { useState } from 'react';
// import axios from 'axios';
import {
    Row, Col, Label, FormGroup, Button,
} from 'reactstrap';
import { useDispatch } from "react-redux";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import LoadingSpinner from '../../../../utils/Helpers/Loading/LoadingSpinner';
import { useNavigate } from "react-router-dom";
import { saveOrganization } from '../../../../redux/actions/organizations/organizationsActions';

import { showMsg } from '../../../../utils/general';

const addAdminUserJWTSchema = Yup.object().shape({
    name: Yup.string()
        .required("Name is Required"),
})


const AddOrganizationsForm = (props) => {
    const {
        objInitialValues
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    
    
    const onSubmitForm = async (values) => {
        console.log('values in onSubmitForm')
        console.log(values)
        saveOrganizationData(values)
    };
    const saveOrganizationData = async (values) => {
        const objPostData = { ...values }
        setIsLoading(true);
        dispatch(saveOrganization(objPostData)).then(async response => {
            setIsLoading(false);
            const data = response.data;
            console.log('response.data')
            console.log(data)
            if (response.data.error === false) {
                showMsg('success', response.data.msg);
                navigate('/organizations/list');
            } else {
                showMsg('error', response.data.msg)
            }
        });
        
    }
    return (
        <Formik
            initialValues={objInitialValues}
            validationSchema={addAdminUserJWTSchema}
            onSubmit={onSubmitForm}
        >
            {({ errors, touched }) => (
                <Form>
                    <Row>
                        <Col md="4">
                            <FormGroup className={`form-group ${errors.name && touched.name && "text-danger"}`}>
                                <Label ><i className='bx bx-user'></i> Name</Label>
                                <Field
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="name"
                                    className={`form-control ${errors.name &&
                                        touched.name &&
                                        "is-invalid"}`}
                                />

                                {errors.name && touched.name ? (
                                    <div className="invalid-feedback">{errors.name}</div>
                                ) : null}
                            </FormGroup>
                        </Col>
                        

                        {/* <Col md="4" sm="4">
                            <FormGroup className={`${errors.drpFrameSize && touched.drpFrameSize && "text-danger"}`}>
                                <Label for="drpFrameSize">Select Frame Size</Label>
                                <Field
                                    as="select"
                                    name="drpFrameSize"
                                    id="drpFrameSize"
                                    className={`form-control ${errors.drpFrameSize &&
                                    touched.drpFrameSize &&
                                    "is-invalid"}`}
                                >
                                    <option value="">Select Frame Size</option>
                                    <option value="four_by_6">4*6</option>
                                    <option value="five_by_7">5*7</option>
                                </Field>
                                {errors.drpFrameSize && touched.drpFrameSize ? (
                                    <div className="invalid-feedback">{errors.drpFrameSize}</div>
                                ) : null}
                            </FormGroup>
                        </Col> */}

                    </Row>
                    
                    {
                        isLoading === false ?
                            <Row>
                                <Col md="12" className="text-end">
                                    <div className="add-listings-btn">
                                        <Button type="submit">Create</Button>
                                    </div>
                                </Col>
                            </Row>
                            :
                            <LoadingSpinner subClass="text-center" color="lightblueColor" />
                    }
                </Form>
            )}
        </Formik>
    );
};

export default AddOrganizationsForm;

import axios from "axios"
import { BASE_URL, ADMIN_USERS_SERVER } from "../../../components/utils/constants/misc"
import { 
    // SET_USER_PASSWORD,
    ADMIN_CREATE_NEW_ADMIN_USER,
    FETCH_ADMIN_USERS,
    CLEAR_ADMIN_USERS,
    DELETE_ADMIN_USER,
    DELETE_ADMIN_USER_FROM_ADMIN_USER_LIST_TBL,
    ADD_UPDATE_ADMIN_USER_IN_ADMIN_USER_LIST_TBL,
    UPDATE_ADMIN_USER
} from '../../actions/types';


export const adminCreateNewAdminUser = (values) => async dispatch => {
    // console.log('values in loginUser')
    // console.log('values in loginUser')
    // console.log(values)
    const res = await axios.post(`${BASE_URL}${ADMIN_USERS_SERVER}/create-new-admin-user`, values);
    dispatch({ type: ADMIN_CREATE_NEW_ADMIN_USER, payload: res.data });
    return Promise.resolve(res);
}

export const updateAdminUser = (values) => async dispatch => {
    // console.log('values in loginUser')
    // console.log('values in loginUser')
    // console.log(values)
    const res = await axios.post(`${BASE_URL}${ADMIN_USERS_SERVER}/update-admin-user`, values);
    dispatch({ type: UPDATE_ADMIN_USER, payload: res.data });
    return Promise.resolve(res);
}

export const fetchAdminUsers = (values) => async dispatch => {
    const res = await axios.post(`${BASE_URL}${ADMIN_USERS_SERVER}/fetch-admin-users`,values);
    dispatch({ type: FETCH_ADMIN_USERS, payload: res.data });
    return Promise.resolve(res);
};


export const clearAdminUsers = () => async dispatch => {
    dispatch({ type: CLEAR_ADMIN_USERS, payload: {objAdminUsers: [],total_admin_users:0, error: false} });
};

export const deleteAdminUser = (user_id) => async dispatch => {
    const res = await axios.delete(`${BASE_URL}${ADMIN_USERS_SERVER}/delete-admin-user/${user_id}`);
    dispatch({ type: DELETE_ADMIN_USER, payload: res.data });
    return Promise.resolve(res);
};

export const deleteAdminUserFromAdminUserListTbl = (user_id) => async dispatch => {
    dispatch({ type: DELETE_ADMIN_USER_FROM_ADMIN_USER_LIST_TBL, payload: user_id });
};

export const addUpdateAdminUserInAdminUserListTbl = (objAdminUser) => async dispatch => {
    dispatch({ type: ADD_UPDATE_ADMIN_USER_IN_ADMIN_USER_LIST_TBL, payload: objAdminUser });
};


// export const setUserPassword = (values) => async dispatch => {
//     const res = await axios.post(`${BASE_URL}${USER_ACCOUNT_SERVER}/set-password`,values);
//     dispatch({ type: SET_USER_PASSWORD, payload: res.data });
//     return Promise.resolve(res);
// }
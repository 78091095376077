import React, { useState, useEffect } from "react"
import {
    // Row,
    // Col,
    // Button,
    Table 
} from "reactstrap"
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { 
    clearOrganizations, 
    fetchOrganizations,
    deleteOrganization,
    deleteOrganizationFromAdminOrganizationListTbl
} from "../../../redux/actions/organizations/organizationsActions";
import LoadOrganizationsList from "./components/LoadOrganizationsList";
import { showMsg } from "../../../utils/general";
// import GoldFrameJpg from '../../../assets/images/gold-frame.jpg';
// import "./scss/FramesListPage.scss"
// import LoadingSpinner from '../../../utils/Helpers/Loading/LoadingSpinner'
import EditOrganizationModal from "./components/EditOrganizationModal/EditOrganizationModal";
const FramesList = (props) => {
    // console.log('props in home')
    // console.log('props in home')
    // console.log('props in home')
    // console.log(props)
    // const { objUser } = props;
    const [perPage, setPerPage] = useState(5);
    const [page, setPage] = useState(1);
    const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(false);
    const [total_frames, setTotalFrames] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [showEditOrganizationModal, setShowEditOrganizationModal] = useState(false);
    const [selectedEditObjOrganization, setSelectedEditObjOrganization] = useState({});

    const handlePageClick = (data) => {
        console.log('data in handlePageClick')
        console.log('data in handlePageClick')
        let selectedPage = data.selected;
        console.log(data)
        console.log('before selectedPage')
        console.log('before selectedPage')
        console.log('before selectedPage')
        console.log(selectedPage)
        selectedPage = selectedPage +1;
        console.log('selectedPage')
        console.log('selectedPage')
        console.log('selectedPage')
        console.log(selectedPage)
        setPage(selectedPage)
        loadMoreFrames(selectedPage,perPage)
        // 
    }
    const dispatch = useDispatch();
    const {  objOrganizations } = useSelector(state => ({
        objOrganizations : state.organizations.organizations.objOrganizations,
    }));

    const loadMoreFrames = (page,perPage) => {
        dispatch(clearOrganizations())
        setIsLoadingOrganizations(true);
        dispatch(fetchOrganizations({page,perPage})).then(async response =>{
            setIsLoadingOrganizations(false);
            if(response.data.error === false){
                setTotalFrames(response.data.total_frames)
                setPageCount(response.data.pageCount)
            }
        });
    }

    useEffect(() => {
        loadMoreFrames(page,perPage);
        // componentWillUnmount 
        return () => {
            dispatch(clearOrganizations())
        }
    }, []);
    const btnOnClickEditFrame = (objUser) => {
        console.log('objUser in btnOnClickEditFrame')
        console.log('objUser in btnOnClickEditFrame')
        console.log(objUser)
        setShowEditOrganizationModal(true);
        setSelectedEditObjOrganization(objUser);
    };
    const btnOnClickDeleteFrame = (objUser) => {
        console.log('objUser in btnOnClickDeleteFrame')
        console.log('objUser in btnOnClickDeleteFrame')
        console.log(objUser)
        let conf = window.confirm('Are you sure?');
        if(!conf){
            return false;
        }
        dispatch(deleteOrganization(objUser._id)).then( response =>{
            if(response.data.error === false){
                showMsg('success',response.data.msg)
                dispatch(deleteOrganizationFromAdminOrganizationListTbl(objUser._id));
            }else{
                showMsg('error',response.data.msg)
            }
        });
        // setShowEditOrganizationModal(true);
        // setSelectedEditObjOrganization(objUser);
    };
    return (
        <div className='HomePage'>
            
            <div className='bookings-listings-box'>
                <h3>Organizations List</h3>

                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {
                        objOrganizations !== undefined ?
                            <tbody>
                                <LoadOrganizationsList
                                    isLoadingOrganizations={isLoadingOrganizations}
                                    objOrganizations={objOrganizations}
                                    total_frames={total_frames}
                                    btnOnClickEditFrame={btnOnClickEditFrame}
                                    btnOnClickDeleteFrame={btnOnClickDeleteFrame}
                                />
                                

                                
                            </tbody>
                        : null
                    }
                </Table>
            </div>
            <ReactPaginate
                    // previousLabel={<ChevronLeft size={15} />}
                    // nextLabel={<ChevronRight size={15} />}
                    previousLabel={<i className="bx bx-chevrons-left"></i>}
                    nextLabel={<i className="bx bx-chevrons-right"></i>}
                    nextClassName="next page-numbers"
                    previousClassName="prev page-numbers"
                    pageClassName="page-numbers"
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    forcePage={page -1} 
                    containerClassName={
                    "pagination-area text-center mt-3 mb-3"
                    }
                    activeClassName={"current"}
            />
            <EditOrganizationModal 
                showEditOrganizationModal={showEditOrganizationModal}
                setShowEditOrganizationModal={setShowEditOrganizationModal}
                selectedEditObjOrganization={selectedEditObjOrganization}
            />
            <div className='flex-grow-1'></div>

        </div>
    )
}
export default FramesList

import React, { useState,useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import AddAppUsersForm from "./components/AddAppUsersForm";
import { fetchAllOrganizations } from "../../../redux/actions/organizations/organizationsActions";
import LoadingSpinner from "../../../utils/Helpers/Loading/LoadingSpinner";
const objInitialValues = {
    name: "",
    email: "",
    password: "",
}
const AddAppUsers = (props) => {
    const [isFetchingAllOrganizations, setIsFetchingAllOrganizations] = useState([]);
    const [formatedOrganizationDrpOptions, setFormatedOrganizationDrpOptions] = useState([]);
    const [selectedObjOrganization, setSelectedObjOrganization] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        setIsFetchingAllOrganizations(true);
        dispatch(fetchAllOrganizations()).then(async response => {
            setIsFetchingAllOrganizations(false);
            const formattedOrganizationsArr = response.data.formattedOrganizationsArr;
            setFormatedOrganizationDrpOptions(formattedOrganizationsArr)
        });
    }, []);
    return (
        <div className='HomePage'>
            <div className='add-listings-box'>
                <h3>Add New App User</h3>
                {
                    isFetchingAllOrganizations === false && formatedOrganizationDrpOptions.length > 0 ?
                    <AddAppUsersForm
                        objInitialValues={objInitialValues}
                        formatedOrganizationDrpOptions={formatedOrganizationDrpOptions}
                        selectedObjOrganization={selectedObjOrganization}
                        setSelectedObjOrganization={setSelectedObjOrganization}
                    />
                    : 
                    <LoadingSpinner subClass="text-center" color="lightblueColor"/>
                }
                
                
            </div>
            <div className='flex-grow-1'></div>

        </div>
    )
}
export default AddAppUsers

import React from 'react';
// import { BrowserRouter, Switch } from 'react-router-dom'
import {
  BrowserRouter,
  Routes,
  Route,
  // Outlet, NavLink
} from "react-router-dom";
// import { BrowserRouter, Switch, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import "animate.css"
// import './assets/css/animate.min.css';
import './assets/css/meanmenu.min.css';
import './assets/css/boxicons.min.css';
import './assets/css/flaticon.css';
import './assets/css/nice-select.min.css';
import './assets/scss/style.scss';
import './assets/scss/responsive.scss';
// import './assets/css/style.css';
// import './assets/css/responsive.css';
import Layout from './Layout/Layout';
// import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// import Home from './pages/Home/Home';
// import Home from './pages/Home/Home';
import OrganizationsList from './pages/Organizations/OrganizationsList/OrganizationsList';
import AddOrganizations from './pages/Organizations/AddOrganizations/AddOrganizations';
import AdminUsersList from './pages/AdminUsers/AdminUsersList/AdminUsersList';
import AddAdminUsers from './pages/AdminUsers/AddAdminUsers/AddAdminUsers';
import AppUsersList from './pages/AppUsers/AppUsersList/AppUsersList';
import AddAppUsers from './pages/AppUsers/AddAppUsers/AddAppUsers';
import Login from './pages/Auth/Login/Login';

import AuthHoc from './Hoc/auth/authHoc';
import RedirectToDashboardIfLoggedInCheck from './Hoc/auth/RedirectToDashboardIfLoggedInCheck';

// import { ThemeProvider } from './contexts/ThemeContext';
import { IndiceProvider } from './contexts';
import NoAuthLayout from './NoAuthLayout/NoAuthLayout';

// const OrdersListPage = authHoc(OrdersList,true);
// const OrganizationsListPage = authHoc(OrganizationsList,true);
// const AddOrganizations = authHoc(AddOrganizations,true);
// const ConnectToYoutubePage = authHoc(ConnectToYoutube,true);
// const AdminUsersListPage = authHoc(AdminUsersList,true);
// const AddAdminUsersPage = authHoc(AddAdminUsers,true);
function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
        <IndiceProvider>
          
          {/* <Switch>
            
            <NoAuthLayoutRoute exact path="/login" component={RedirectToDashboardIfLoggedInCheck(Login,true)} />  
            
            
          </Switch> */}
          <Routes>
            <Route element={<Layout />}>
              
            <Route index path="/" element={
                 <AuthHoc 
                    checkAuth={true} 
                    isRedirect={true} 
                    Component={AppUsersList}
                  /> 
                }   
              />
              <Route index path="/organizations/add" element={
                  <AuthHoc 
                    checkAuth={true} 
                    isRedirect={true} 
                    Component={AddOrganizations}
                  /> 
                } 
              />
              <Route index path="/organizations/list" element={
                  <AuthHoc 
                    checkAuth={true} 
                    isRedirect={true} 
                    Component={OrganizationsList}
                  /> 
                } 
              />
              <Route index path="/app/users/add" element={
                 <AuthHoc 
                    checkAuth={true} 
                    isRedirect={true} 
                    Component={AddAppUsers}
                  /> 
                }   
              />
              <Route index path="/app/users/list" element={
                 <AuthHoc 
                    checkAuth={true} 
                    isRedirect={true} 
                    Component={AppUsersList}
                  /> 
                }   
              />
              <Route index path="/admin/users/add" element={
                 <AuthHoc 
                    checkAuth={true} 
                    isRedirect={true} 
                    Component={AddAdminUsers}
                  /> 
                }   
              />
              <Route index path="/admin/users/list" element={
                 <AuthHoc 
                    checkAuth={true} 
                    isRedirect={true} 
                    Component={AdminUsersList}
                  /> 
                }   
              />
                {/* <Route  path="teams" element={<Home />} /> */}
                {/* <Route path="teams" element={<Teams />}>
                  <Route path=":teamId" element={<Team />} />
                  <Route path="new" element={<NewTeamForm />} />
                  <Route index element={<LeagueStandings />} />
                </Route> */}
            </Route>
            <Route element={<NoAuthLayout />}>
              
              <Route index path="/login" element={
                  <RedirectToDashboardIfLoggedInCheck 
                    checkAuth={true} 
                    Component={Login}
                  />
                }
              />
                
            </Route>
          </Routes>
          
        </IndiceProvider>
    </BrowserRouter>
  );
}

export default App;

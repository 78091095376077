import React from "react"
import AddOrganizationsForm from "./components/AddOrganizationsForm";
// import {
//     Row,
//     Col,
//     Button,
//     FormGroup,
//     Label,
//     Input,
//     FormText,
// } from "reactstrap"
// import { useContext } from 'react';
// import { ThemeContext } from "../../contexts/ThemeContext";

// import "./scss/AddOrganizationsPage.scss"
// import Features from './components/Features';
// import Category from './components/Category';
// import ListingArea from '../../common/components/ListingArea';
// import DestinationsTwo from '../../common/components/DestinationsTwo';
// import Feedback from '../../common/components/Feedback';
// import HowItWorks from '../../common/components/HowItWorks';
// import Blog from '../../common/components/Blog';
// import AppDownload from '../../common/components/AppDownload';
const objInitialValues = {
    name: "",
}
const AddOrganizations = (props) => {
    // console.log('props in home')
    // console.log('props in home')
    // console.log('props in home')
    // console.log(props)
    // const { objUser } = props;

    return (
        <div className='HomePage'>
            <div className='add-listings-box'>
                <h3>Add New Organization</h3>
                <AddOrganizationsForm
                    objInitialValues={objInitialValues}
                />
            </div>
            <div className='flex-grow-1'></div>

        </div>
    )
}
export default AddOrganizations

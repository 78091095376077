import { ADMIN_LOGIN_USER } from '../../actions/types';

export const adminLogin = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_LOGIN_USER: {
            return { ...state, objLoginFormSuccess: action.payload }
        }
        default: {
            return state
        }
    }
}

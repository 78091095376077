import React, { useEffect, useRef, useState } from 'react';
import { adminAuth } from '../../redux/actions/adminAuth/adminUserActions';
import { useDispatch, useSelector } from "react-redux";
// import LoadingSpinner from '../../helpers/Loading/LoadingSpinner';
import LoadingSpinner from '../../utils/Helpers/Loading/LoadingSpinner';
import { useNavigate } from "react-router-dom";
import { fetchSettings } from '../../redux/actions/settings/settingsDataActions';
// import {
//     Outlet
// } from "react-router-dom";
const AuthenticationCheck = ({Component, checkAuth, isRedirect }) => {
    // console.log('props in AuthenticationCheck')
    // console.log('props in AuthenticationCheck')
    // console.log(props)
    const dispatch = useDispatch();
    const _isMounted = useRef(true); // Initial value _isMounted = true
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (_isMounted.current) { // Check always mounted component
            // continue treatment of AJAX response... ;
            loadAuthData();
        }
        return () => {
            _isMounted.current = false;
        };
    });
    
    const { adminUser } = useSelector(state => ({
        adminUser: state.adminAuth.adminUser,
    }));

    // const { objFetchSettingsData } = useSelector(state => ({
    //     objFetchSettingsData: state.settings.settingsData.objFetchSettingsData,
    // }));

    const loadAuthData = async () => {
        setLoading(true);
        const objAdminUserAuthResp = await dispatch(adminAuth());
        const objFetchAdminSettingsResp = await dispatch(fetchSettings());
        console.log('objAdminUserAuthResp in AuthenticationCheck')
        console.log('objAdminUserAuthResp in AuthenticationCheck')
        console.log(objAdminUserAuthResp)
        console.log('objFetchAdminSettingsResp in AuthenticationCheck')
        console.log('objFetchAdminSettingsResp in AuthenticationCheck')
        console.log(objFetchAdminSettingsResp)
        const objAdminUserAuth = objAdminUserAuthResp.data;
        if (checkAuth && !objAdminUserAuth.isAuth) {
            if (isRedirect) {
                navigate('/login');
                return false;
            }
        } else if (checkAuth && objAdminUserAuth.isAuth) {

        }
        
        setLoading(false);
    }



    console.log('adminUser in AuthenticationCheck')
    console.log('adminUser in AuthenticationCheck')
    console.log(adminUser)

    if (loading) {
        return (
            <LoadingSpinner subClass="text-center" color="lightblueColor" />
        )
    }
    console.log('loading after in AuthenticationCheck')
    console.log('loading after in AuthenticationCheck')
    console.log(loading)
    console.log('adminUser after loading AuthenticationCheck')
    console.log('adminUser after loading AuthenticationCheck')
    console.log(adminUser)
    console.log('Object.keys(adminUser).length after loading AuthenticationCheck')
    console.log('Object.keys(adminUser).length after loading AuthenticationCheck')
    console.log(Object.keys(adminUser).length)
    // if (loading === false && checkAuth && Object.keys(adminUser).length === 0) {
    // // if (loading === false && checkAuth && Object.keys(adminUser).length === 0) {
    //     return (
    //         <LoadingSpinner subClass="text-center" color="success" />
    //     )
    // }
    if (loading === false && checkAuth && Object.keys(adminUser).length > 0) {
        // console.log('user in authhoc')
        // console.log('user in authhoc')
        // console.log(user)
        // console.log('Object.keys(user).length in authhoc')
        // console.log('Object.keys(user).length in authhoc')
        // console.log(Object.keys(user).length)
        
        let { objAdminUser } = adminUser;
        // let { objSettings } = objFetchSettingsData;
        
        // alert(Object.keys(objAdminUserAuth).length)
        // alert(objAdminUserAuth.email)
        return (
            <Component
                objAdminUser={objAdminUser}
                // objSettings={objSettings}
            />
        )
    } else {
        return (
            <LoadingSpinner
                subClass="text-center" color="lightblueColor"
            />
        )
    }
};

export default AuthenticationCheck




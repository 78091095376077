import { useState,useContext} from 'react';
import { Link } from 'react-router-dom';
import {IndiceContext} from "../contexts";
import LogoPng from '../assets/images/logo.png';
import { useDispatch } from "react-redux";
import { logoutAdminUser } from '../redux/actions/adminAuth/adminUserActions';
import { showMsg } from '../utils/general';
import {Button} from 'reactstrap';
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../utils/Helpers/Loading/LoadingSpinner';
const DashboardNavbar = () => {
  const {displaySideMenu,toggleSideMenu} = useContext(IndiceContext);
  const [displayOrganizationsListing, setDisplayOrganizationsListing] = useState(false);
  const [displayAdminUsersListing, setDisplayAdminUsersListing] = useState(false);
  const [displayAppUsersListing, setDisplayAppUsersListing] = useState(false);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const framesListingToggle = () => {
    setDisplayOrganizationsListing(!displayOrganizationsListing);
  };
  const [isLoading, setIsLoading] = useState(false);
  const btnOnClickLogout = () =>{
    setIsLoading(true);
    dispatch(logoutAdminUser()).then(response => {
        setIsLoading(false);
        // console.log('props.user after logout')
        // console.log('props.user after logout')
        // console.log('props.user after logout')
        // console.log(props.user)
        // console.log('response.data after logout')
        // console.log('response.data after logout')
        // console.log('response.data after logout')
        // console.log(response.data)
  
        if(response.data.error === false){
        // if(props.user.logOutSuccess.error === false){
          localStorage.removeItem('token')
          showMsg('success',"You have been successfully logged out.");
          navigate('/login');
        }else{
          showMsg("error","Opps Something went wrong please try again.");
        }
    });
}


  const adminUsersListingToggle = () => {
    setDisplayAdminUsersListing(!displayAdminUsersListing);
  };

  const appUsersListingToggle = () => {
    setDisplayAppUsersListing(!displayAppUsersListing);
  };
  

  return (
    <>
      <div className={displaySideMenu ? 'sidemenu-area active-sidemenu-area':'sidemenu-area'}>
        <div className='sidemenu-header'>
          <Link to='/'>
            <a className='navbar-brand d-flex align-items-center'>
              <img src={LogoPng} alt='image' />
            </a>
          </Link>

          <div className='responsive-burger-menu d-block d-lg-none' onClick={toggleSideMenu}>
            <i className='bx bx-x'></i>
          </div>
        </div>

        <div className='sidemenu-body'>
          <ul
            className='sidemenu-nav metisMenu h-100'
            id='sidemenu-nav'
            data-simplebar
          >
            

            

            {/* <li className='nav-item'>
              <Link to='/dashboard/messages' >
                <a>
                  <span className='icon'>
                    <i className='bx bx-envelope-open'></i>
                  </span>
                  <span className='menu-title'>Messages</span>
                  <span className='badge'>3</span>
                </a>
              </Link>
            </li>

            <li className='nav-item'>
              <Link to='/dashboard/bookings' >
                <a>
                  <span className='icon'>
                    <i className='bx bx-copy'></i>
                  </span>
                  <span className='menu-title'>Bookings</span>
                </a>
              </Link>
            </li>

            <li className='nav-item'>
              <Link to='/dashboard/wallet' >
                <a>
                  <span className='icon'>
                    <i className='bx bx-wallet'></i>
                  </span>
                  <span className='menu-title'>Wallet</span>
                </a>
              </Link>
            </li> */}
            {/* Start App Users */}

            <li className='nav-item-title'>App Users</li>

            <li className='nav-item'>
              <a
                href='#'
                className='collapsed-nav-link nav-link'
                onClick={appUsersListingToggle}
              >
                <span className='icon'>
                  <i className='bx bx-layer'></i>
                </span>
                <span className='menu-title'>App Users</span>
              </a>

              <ul
                className={
                  displayAppUsersListing
                    ? 'sidemenu-nav-second-level show'
                    : 'sidemenu-nav-second-level sidemenu-nav-display'
                }
              >
                <li className='nav-item active-section'>
                  <Link
                    to='/app/users/list'
                  >
                    <a>
                      <span className='menu-title'>App Users List</span>
                    </a>
                  </Link>
                </li>

                <li className='nav-item active-section'>
                  <Link
                    to='/app/users/add'
                    
                  >
                    <a>
                      <span className='menu-title'>Add App Users</span>
                    </a>
                  </Link>
                </li>

                {/* <li className='nav-item active-section'>
                  <Link
                    to='/dashboard/my-listing/expired'
                    
                  >
                    <a>
                      <span className='menu-title'>Expired</span>
                      <span className='badge red'>2</span>
                    </a>
                  </Link>
                </li> */}
              </ul>
            </li>

            {/* App Users */}
            

            <li className='nav-item-title'>Organizations</li>

            <li className='nav-item'>
              <a
                href='#'
                className='collapsed-nav-link nav-link'
                onClick={framesListingToggle}
              >
                <span className='icon'>
                  <i className='bx bx-layer'></i>
                </span>
                <span className='menu-title'>Organization</span>
              </a>

              <ul
                className={
                  displayOrganizationsListing
                    ? 'sidemenu-nav-second-level show'
                    : 'sidemenu-nav-second-level sidemenu-nav-display'
                }
              >
                <li className='nav-item active-section'>
                  <Link
                    to='/organizations/list'
                    
                  >
                    <a>
                      <span className='menu-title'>Organization List</span>
                      {/* <span className='badge'>5</span> */}
                    </a>
                  </Link>
                </li>

                <li className='nav-item active-section'>
                  <Link
                    to='/organizations/add'
                    
                  >
                    <a>
                      <span className='menu-title'>Add Organizations</span>
                      {/* <span className='badge yellow'>1</span> */}
                    </a>
                  </Link>
                </li>

                {/* <li className='nav-item active-section'>
                  <Link
                    to='/dashboard/my-listing/expired'
                    
                  >
                    <a>
                      <span className='menu-title'>Expired</span>
                      <span className='badge red'>2</span>
                    </a>
                  </Link>
                </li> */}
              </ul>
            </li>
            <li className='nav-item-title'>Admins</li>
            <li className='nav-item'>
              <a
                href='#'
                className='collapsed-nav-link nav-link'
                onClick={adminUsersListingToggle}
              >
                <span className='icon'>
                  <i className='bx bx-layer'></i>
                </span>
                <span className='menu-title'>Admin Users</span>
              </a>

              <ul
                className={
                  displayAdminUsersListing
                    ? 'sidemenu-nav-second-level show'
                    : 'sidemenu-nav-second-level sidemenu-nav-display'
                }
              >
                <li className='nav-item active-section'>
                  <Link
                    to='/admin/users/list'
                    
                  >
                    <a>
                      <span className='menu-title'>Admins Users List</span>
                    </a>
                  </Link>
                </li>

                <li className='nav-item active-section'>
                  <Link
                    to='/admin/users/add'
                    
                  >
                    <a>
                      <span className='menu-title'>Add Admin Users</span>
                    </a>
                  </Link>
                </li>

                
              </ul>
            </li>
            {/* <li className='nav-item'>
              <Link to='/dashboard/reviews' >
                <a>
                  <span className='icon'>
                    <i className='bx bx-star'></i>
                  </span>
                  <span className='menu-title'>Reviews</span>
                </a>
              </Link>
            </li>

            <li className='nav-item'>
              <Link to='/dashboard/bookmarks' >
                <a>
                  <span className='icon'>
                    <i className='bx bx-heart'></i>
                  </span>
                  <span className='menu-title'>Bookmarks</span>
                </a>
              </Link>
            </li>

            <li className='nav-item'>
              <Link to='/dashboard/add-listing' >
                <a>
                  <span className='icon'>
                    <i className='bx bx-plus-circle'></i>
                  </span>
                  <span className='menu-title'>Add Listings</span>
                </a>
              </Link>
            </li>

            <li className='nav-item'>
              <Link to='/dashboard/invoice' >
                <a>
                  <span className='icon'>
                    <i className='bx bx-certification'></i>
                  </span>
                  <span className='menu-title'>Invoice</span>
                </a>
              </Link>
            </li>

            <li className='nav-item-title'>Account</li>

            <li className='nav-item'>
              <Link to='/dashboard/profile' >
                <a>
                  <span className='icon'>
                    <i className='bx bxs-user-circle'></i>
                  </span>
                  <span className='menu-title'>Profile</span>
                </a>
              </Link>
            </li> */}

            <li className='nav-item'>
              {
                isLoading === false ?
                <a href='#' onClick={() => btnOnClickLogout()}>
                  <span className='icon'>
                    <i className='bx bx-log-out'></i>
                  </span>
                  <span className='menu-title'>Logout</span>
                </a>
                : 
                <LoadingSpinner subClass="text-center" color="lightblueColor" size="lg" />
              }
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default DashboardNavbar;

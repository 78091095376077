import axios from "axios"
import { 
    SETTINGS_SERVER,
    BASE_URL
} from "../../../components/utils/constants/misc"
import { 
    FETCH_SETTINGS,
} from '../../actions/types';

export const fetchSettings = (values) => async dispatch => {
    // console.log('values in createPaymentIntentOfPropertySubscription')
    // console.log('values in createPaymentIntentOfPropertySubscription')
    // console.log(values)
    
    const res = await axios.post(`${BASE_URL}${SETTINGS_SERVER}/fetch-settings`, values);
    dispatch({ type: FETCH_SETTINGS, payload: res.data });
    return Promise.resolve(res);
}
import React, { useEffect } from 'react';
import {
    Outlet
} from "react-router-dom";
// import './App.css';
import NavbarThree from './NavbarThree';
import DashboardNavbar from './DashboardNavbar';

const Layout = () => {
    
    return (
        <>
            <DashboardNavbar />
            <div className='main-content d-flex flex-column'>
                <NavbarThree />
                <Outlet />
            </div>
            {/* <Footer/>
            <Copyright/> */}
        </>
    );
};

export default Layout;
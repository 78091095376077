import axios from "axios"
import { BASE_URL, ADMIN_USERS_SERVER } from "../../../components/utils/constants/misc"
import { ADMIN_LOGIN_USER } from '../../actions/types';

export const adminLoginUser = (values) => async dispatch => {
    // console.log('values in loginUser')
    // console.log('values in loginUser')
    // console.log(values)
    const res = await axios.post(`${BASE_URL}${ADMIN_USERS_SERVER}/login`, values);
    dispatch({ type: ADMIN_LOGIN_USER, payload: res.data });
    return Promise.resolve(res);
}
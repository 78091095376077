import React from "react"
import {
    Row,
    Col,
    Button,
    Label,
    FormGroup
} from "reactstrap"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
const validationSchema = Yup.object().shape({
    txtNameOfUser: Yup.string(),
    txtEmailofUser: Yup.string().email("Invalid email"),
})
// import "./scss/AppUsersListSearchFormPage.scss"
const AppUsersListSearchForm = (props) => {
    const {btnOnClickSearch,btnOnClickReset} = props;
    const onSubmitForm = async (values) => {
        console.log('values in submiy')
        console.log(values)
        btnOnClickSearch(values)
    };

    

    return (
        <div className='add-listings-box'>
            <h3>Search Filters</h3>
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitForm}
            >
                {({ errors, touched }) => (
                <Form>
                    <Row>
                        <Col md="3" sm="3">
                            <FormGroup className={`${errors.txtNameOfUser && touched.txtNameOfUser && "mb-3"}`}>
                                <Label for="txtNameOfUser">Name of User</Label>
                                <Field
                                    type="text"
                                    name="txtNameOfUser"
                                    id="txtNameOfUser"
                                    placeholder='Name of User'  
                                    className={`form-control ${errors.txtNameOfUser &&
                                        touched.txtNameOfUser &&
                                        "is-invalid"}`}
                                />
                                {errors.txtNameOfUser && touched.txtNameOfUser ? (
                                    <div className="invalid-feedback">{errors.txtNameOfUser}</div>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="3">
                            <FormGroup className={`${errors.txtEmailofUser && touched.txtEmailofUser && "mb-3"}`}>
                                <Label for="txtEmailofUser">Email of User</Label>
                                <Field
                                    type="email"
                                    name="txtEmailofUser"
                                    id="txtEmailofUser"
                                    className={`form-control ${errors.txtEmailofUser &&
                                        touched.txtEmailofUser &&
                                        "is-invalid"}`}
                                />
                                {errors.txtEmailofUser && touched.txtEmailofUser ? (
                                    <div className="invalid-feedback">{errors.txtEmailofUser}</div>
                                ) : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="12" className="text-end">
                            <div className="add-listings-btn">
                                <Button
                                    color="warning"
                                    type="reset"
                                    className=""
                                    onClick={() => {
                                        btnOnClickReset()
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button color="success" type="submit" className="ml-10">
                                    Search
                                </Button>
                            </div>
                            <div className="add-listings-btn">

                            </div>
                        </Col>
                    </Row>                    
                
                </Form>
                )}
            </Formik>
            
        </div>
    )
}
export default AppUsersListSearchForm

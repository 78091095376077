import moment from 'moment';
export function getNextYear() {
    let oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    return oneYearFromNow.getFullYear();
}

export function getCurrentYear ()  {
    let currentDateTime = new Date();
    return currentDateTime.getFullYear();
}

export function formatDate(dateTime) {
    const _dateTime = moment(new Date(dateTime)).format('DD-MMM-YYYY');
    return _dateTime;
}

export function formatDateWithFullMonth(date){
    date = new Date(date)
    let m = moment(date)
    return m.format('DD MMMM, YYYY');
}

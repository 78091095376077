import axios from "axios"
import { BASE_URL, ADMIN_ORGANIZATION_SERVER } from "../../../components/utils/constants/misc"
import { 
    // SET_USER_PASSWORD,
    SAVE_ORGANIZATION,
    FETCH_ORGANIZATIONS,
    CLEAR_ORGANIZATIONS,
    DELETE_ORGANIZATION,
    DELETE_ORGANIZATION_FROM_ADMIN_ORGANIZATION_LIST_TBL,
    UPDATE_ORGANIZATION,
    ADD_UPDATE_ORGANIZATION_IN_ORGANIZATION_LIST_TBL,
    FETCH_ALL_ORGANIZATIONS
} from '../../actions/types';


export const saveOrganization = (values) => async dispatch => {
    // console.log('values in loginUser')
    // console.log('values in loginUser')
    // console.log(values)
    const res = await axios.post(`${BASE_URL}${ADMIN_ORGANIZATION_SERVER}/save-organization`, values);
    dispatch({ type: SAVE_ORGANIZATION, payload: res.data });
    return Promise.resolve(res);
}

export const updateOrganization = (values) => async dispatch => {
    // console.log('values in loginUser')
    // console.log('values in loginUser')
    // console.log(values)
    const res = await axios.post(`${BASE_URL}${ADMIN_ORGANIZATION_SERVER}/update-organization`, values);
    dispatch({ type: UPDATE_ORGANIZATION, payload: res.data });
    return Promise.resolve(res);
}

export const fetchOrganizations = (values) => async dispatch => {
    const res = await axios.post(`${BASE_URL}${ADMIN_ORGANIZATION_SERVER}/fetch-organizations`,values);
    dispatch({ type: FETCH_ORGANIZATIONS, payload: res.data });
    return Promise.resolve(res);
};


export const clearOrganizations = () => async dispatch => {
    dispatch({ type: CLEAR_ORGANIZATIONS, payload: {objOrganizations: [],total_organizations:0, error: false} });
};

export const deleteOrganization = (organization_id) => async dispatch => {
    const res = await axios.delete(`${BASE_URL}${ADMIN_ORGANIZATION_SERVER}/delete-organization/${organization_id}`);
    dispatch({ type: DELETE_ORGANIZATION, payload: res.data });
    return Promise.resolve(res);
};

export const deleteOrganizationFromAdminOrganizationListTbl = (organization_id) => async dispatch => {
    dispatch({ type: DELETE_ORGANIZATION_FROM_ADMIN_ORGANIZATION_LIST_TBL, payload: organization_id });
};

export const addUpdateOrganizationInOrganizationListTbl = (objOrganization) => async dispatch => {
    dispatch({ type: ADD_UPDATE_ORGANIZATION_IN_ORGANIZATION_LIST_TBL, payload: objOrganization });
};

export const fetchAllOrganizations = (values) => async dispatch => {
    const res = await axios.get(`${BASE_URL}${ADMIN_ORGANIZATION_SERVER}/fetch-all-organizations`,values);
    dispatch({ type: FETCH_ALL_ORGANIZATIONS, payload: res.data });
    return Promise.resolve(res);
};
import React, { Fragment } from "react"
import OrganizationsListTr from './OrganizationsListTr';
const OrganizationsListItemBlock = (props) => {
    const renderOrganizationsList = () => (
        props.list ?
            props.list.map(objOrganization => (
                <OrganizationsListTr
                    key={objOrganization._id}
                    {...objOrganization}
                    btnOnClickEditFrame={props.btnOnClickEditFrame}
                    btnOnClickDeleteFrame={props.btnOnClickDeleteFrame}
                />
            ))
            : null
    )
    return (
        <Fragment>
            {props.list ?
                props.list.length === 0 && props.isLoadingAdminUsers === false ?
                    <tr className="text-center">
                        <td className="text-center" colSpan="10"> No records found.</td>
                    </tr>
                    : null
                : null}
            {renderOrganizationsList(props.list)}
        </Fragment>
    )
}
export default OrganizationsListItemBlock

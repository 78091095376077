import React, { Fragment, useState, useEffect } from "react"
import {  FormGroup, Label, Button, Row, Col } from "reactstrap"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux";
import LoadingSpinner from '../../../../../utils/Helpers/Loading/LoadingSpinner'
import { showMsg } from "../../../../../utils/general";
import { addUpdateAdminUserInAdminUserListTbl, updateAdminUser } from "../../../../../redux/actions/adminAuth/adminUserAccountActions";


const initialValues = {
    name:'',
    password:'',
}
const editAdminUserFormSchema = Yup.object().shape({
    name: Yup.string().required("full name is Required"),
    password: Yup.string()
      .min(8, "Password is too short!(min 8 characters are required)")
      .required("Password is required"),
})  
const EditAdminUserModalBody = (props) => {
    const {selectedEditObjUser} = props;
    const [objInitialValues, setObjInitialValues] = useState(initialValues);
    const dispatch = useDispatch();
    const [isEditingAdminUser,setIsEditingAdminUser] = useState(false);
    useEffect(() => {
        let __ObjInitialValues = {
            name: selectedEditObjUser.full_name,
            password:'',
        };
        setObjInitialValues(__ObjInitialValues);
    },[selectedEditObjUser]);
    const onSubmitForm = async (values) => {
        console.log('values in submit in EditAdminUserModalBody')
        console.log(values)
        let objPostData = {user_id:selectedEditObjUser._id,...values}
        setIsEditingAdminUser(true)
        dispatch(updateAdminUser(objPostData)).then( response =>{
            setIsEditingAdminUser(false)
            if(response.data.error === false){
                showMsg('success',response.data.msg)
                dispatch(addUpdateAdminUserInAdminUserListTbl(response.data.objAdminUser));
                props.toggle()
            }else{
                showMsg('error',response.data.msg)
            }
        });
        // console.log('selectedObjUser')
        // console.log(selectedObjUser)
    };

    
    
    return (
        <Fragment>
            <Formik
                initialValues={objInitialValues}
                validationSchema={editAdminUserFormSchema}
                onSubmit={onSubmitForm}
                enableReinitialize={true}
            >
                {({ errors, touched }) => (
                <Form>
                    <Row>
                        <Col md="6">
                            <FormGroup className={`form-group ${errors.name && touched.name && "text-danger"}`}>
                                <Label ><i className='bx bx-user'></i> Name</Label>
                                <Field
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Name"
                                    className={`form-control ${errors.name &&
                                        touched.name &&
                                        "is-invalid"}`}
                                />
                                
                                {errors.name && touched.name ? (
                                    <div className="invalid-feedback">{errors.name}</div>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup className={`form-group ${errors.password && touched.password && "text-danger"}`}>
                                <Label for="password"><i className='bx bx-lock-alt'></i> Password</Label>
                                <Field
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    className={`form-control ${errors.password &&
                                        touched.password &&
                                        "is-invalid"}`}
                                />
                                {errors.password && touched.password ? (
                                    <div className="invalid-feedback">{errors.password}</div>
                                ) : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="text-end mb-1">
                        {
                            isEditingAdminUser === false ? 
                                <div className="add-listings-btn">
                                    <Button color="success" type="submit" >
                                        Edit Admin User
                                    </Button>
                                </div>
                            :<LoadingSpinner subClass="text-center" color="lightblueColor" size="lg" />
                        }
                    </div>
                    
                
                </Form>
                )}
            </Formik>
      </Fragment>
    )
}
export default EditAdminUserModalBody

import React, { Fragment } from "react"
import AdminUsersListTr from './AdminUsersListTr';
const AdminUsersUsersListItemBlock = (props) => {
    const renderAdminUsersList = () => (
        props.list ?
            props.list.map(objAdminUser => (
                <AdminUsersListTr
                    key={objAdminUser._id}
                    {...objAdminUser}
                    btnOnClickEditUser={props.btnOnClickEditUser}
                    btnOnClickDeleteUser={props.btnOnClickDeleteUser}
                />
            ))
            : null
    )
    return (
        <Fragment>
            {props.list ?
                props.list.length === 0 && props.isLoadingAdminUsers === false ?
                    <tr className="text-center">
                        <td className="text-center" colSpan="10"> No records found.</td>
                    </tr>
                    : null
                : null}
            {renderAdminUsersList(props.list)}
        </Fragment>
    )
}
export default AdminUsersUsersListItemBlock

import {
    ADMIN_CREATE_NEW_ADMIN_USER,
    FETCH_ADMIN_USERS,
    CLEAR_ADMIN_USERS,
    DELETE_ADMIN_USER,
    DELETE_ADMIN_USER_FROM_ADMIN_USER_LIST_TBL,
    UPDATE_ADMIN_USER,
    ADD_UPDATE_ADMIN_USER_IN_ADMIN_USER_LIST_TBL,
} from '../../actions/types';

export const adminUserAccount = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_CREATE_NEW_ADMIN_USER: {
            return { ...state, objCreateAdminUserSuccess: action.payload }
        }
        case FETCH_ADMIN_USERS: {
            // console.log('state.objAdminUsers in store')
            // console.log('state.objAdminUsers in store')
            // console.log(state.objAdminUsers)
            if (state.objAdminUsers === undefined) {
                return { ...state, objAdminUsers: action.payload }
            } else {
                return {
                    ...state,
                    objAdminUsers: {
                        ...state.objAdminUsers,
                        error: action.payload.error,
                        msg: action.payload.msg,
                        total_admin_users: action.payload.total_admin_users,
                        // objAdminUsers:action.payload.objAdminUsers,
                        objAdminUsers: [...state.objAdminUsers.objAdminUsers, ...action.payload.objAdminUsers]
                    }
                }
            }
        }

        case CLEAR_ADMIN_USERS: {
            return { ...state, objAdminUsers: action.payload }
        }
        case DELETE_ADMIN_USER: {
            return { ...state, objDeletAdminUserSuccess: action.payload }
        }
        case DELETE_ADMIN_USER_FROM_ADMIN_USER_LIST_TBL: {
            let user_id = action.payload;
            let objAdminUsers = state.objAdminUsers;
            let items = state.objAdminUsers.objAdminUsers.slice();
            let total_admin_users = state.objAdminUsers.total_admin_users;
            const itemIndex = items.findIndex(item => item._id === user_id);
            console.log('itemIndex')
            console.log(itemIndex)
            console.log('total_admin_users')
            console.log(total_admin_users)
            // add new one
            if (itemIndex !== -1) {
                const nextState = items.filter(item => item._id !== user_id)
                console.log('nextState')
                console.log('nextState')
                console.log(nextState)
                total_admin_users = total_admin_users - 1;
                console.log('total_admin_users after')
                console.log('total_admin_users after')
                console.log(total_admin_users)
                return {
                    ...state,
                    objAdminUsers: {
                        ...objAdminUsers,
                        objAdminUsers: nextState,
                        total_admin_users,
                    },
                }
            } else {
                return state;
            }
        }

        case UPDATE_ADMIN_USER: {
            return { ...state, objUpdateAdminUserFormSuccess: action.payload }
        }

        case ADD_UPDATE_ADMIN_USER_IN_ADMIN_USER_LIST_TBL: {
            let objAdminUser = action.payload;
            let objAdminUsers = state.objAdminUsers;
            let items = state.objAdminUsers.objAdminUsers.slice();
            let total_admin_users = state.objAdminUsers.total_admin_users;
            const itemIndex = items.findIndex(item => item._id === objAdminUser._id);
            console.log('itemIndex in ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
            console.log('itemIndex in ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
            console.log('itemIndex in ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
            console.log('itemIndex in ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
            console.log(itemIndex)
            // add new one
            if (itemIndex === -1) {
                console.log('I am in if of ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
                console.log('I am in if of ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
                items.unshift(objAdminUser)
                // items.push(objSignal)
                total_admin_users = total_admin_users + 1;
                return {
                    ...state,
                    objAdminUsers: {
                        ...objAdminUsers,
                        objAdminUsers: items,
                        total_admin_users,
                    },
                }
            } else {
                console.log('I am in else of ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
                console.log('I am in else of ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
                // update the existing one
                let objAdminUsers = state.objAdminUsers.objAdminUsers.slice();
                const nextStateobjAdminUsers = objAdminUsers.map(item => {
                    console.log('item._id')
                    console.log('item._id')
                    console.log(item._id)
                    console.log('objAdminUser._id')
                    console.log('objAdminUser._id')
                    console.log(objAdminUser._id)
                    if (item._id !== objAdminUser._id) {
                        // not our item, return it as is
                        return item;
                    }
                    console.log("Item found ")
                    console.log("Item found ")
                    console.log("Item found ")
                    // this is our relevant item, return a new copy of it with modified fields
                    return {
                        // ...item,
                        ...objAdminUser,
                    }
                });
                return {
                    ...state,
                    objAdminUsers: {
                        ...objAdminUsers,
                        objAdminUsers: nextStateobjAdminUsers,
                    },
                }
            }
        }
        
        
        default: {
            return state
        }
    }
}

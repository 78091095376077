import React, { Fragment } from "react"
import {
    Button
} from "reactstrap"
const AppUsersListTr = (props) => {
    
    return (
        <Fragment>
            <tr>
                <td className='name'>
                    <div className='info'>
                        <span>Name: {props.full_name}</span>
                        <ul>
                            <li>
                                Email: <strong>{props.email}</strong>
                            </li>
                        </ul>
                    </div>
                    <p>Organization: {props.organization_id.name}</p>
                </td>
                <td className='action'>
                    <Button className='default-btn'
                        onClick={() => {
                            props.btnOnClickEditUser(props)
                        }}
                    >
                        <i className='bx bx-edit'></i> Edit
                    </Button>
                    <Button className='default-btn danger' color="danger"
                        onClick={() => {
                            props.btnOnClickDeleteUser(props)
                        }}
                    >
                        <i className='bx bx-x-circle'></i> Delete
                    </Button>
                </td>
            </tr>
        </Fragment>
    )
}
export default AppUsersListTr

import React, { useState,useEffect } from "react"
import {
    // Row,
    // Col,
    // Button,
    Table 
} from "reactstrap"
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { 
    clearAppUsers, 
    fetchAppUsers,
    deleteAppUser,
    deleteAppUserFromAppUserListTbl
} from "../../../redux/actions/appUserAuth/appUserAccountActions";
import AppUsersLoadUserList from "./components/AppUsersLoadUserList";
import { showMsg } from "../../../utils/general";
import LoadingSpinner from '../../../utils/Helpers/Loading/LoadingSpinner'
import EditAppUserModal from "./components/EditAppUserModal/EditAppUserModal";
import { fetchAllOrganizations } from "../../../redux/actions/organizations/organizationsActions";
import AppUsersListSearchForm from "./components/AppUsersListSearchForm/AppUsersListSearchForm";
// import "./scss/AppUsersListPage.scss"
const initialValues = {
    txtNameOfUser: '',
    txtEmailofUser: '',
}
const AppUsersList = (props) => {
    // console.log('props in home')
    // console.log('props in home')
    // console.log('props in home')
    // console.log(props)
    // const { objUser } = props;
    const [perPage, setPerPage] = useState(5);
    const [page, setPage] = useState(1);
    const [isLoadingAppUsers, setIsLoadingAppUsers] = useState(false);
    const [total_app_users, setTotalAppUsers] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [showEditAppUserModal, setShowEditAppUserModal] = useState(false);
    const [selectedEditObjUser, setSelectedEditObjUser] = useState({});
    const [isFetchingAllOrganizations, setIsFetchingAllOrganizations] = useState([]);
    const [formatedOrganizationDrpOptions, setFormatedOrganizationDrpOptions] = useState([]);

    const [searchData, setSearchData] = useState(initialValues);

    const handlePageClick = (data) => {
        console.log('data in handlePageClick')
        console.log('data in handlePageClick')
        let selectedPage = data.selected;
        console.log(data)
        console.log('before selectedPage')
        console.log('before selectedPage')
        console.log('before selectedPage')
        console.log(selectedPage)
        selectedPage = selectedPage +1;
        console.log('selectedPage')
        console.log('selectedPage')
        console.log('selectedPage')
        console.log(selectedPage)
        setPage(selectedPage)
        loadMoreAppUsers(selectedPage,perPage,searchData)
        // 
    }
    const dispatch = useDispatch();
    const {  objAppUsers } = useSelector(state => ({
        objAppUsers : state.appUserAuth.appUserAccount.objAppUsers,
    }));

    const loadMoreAppUsers = (page,perPage,_searchData) => {
        dispatch(clearAppUsers())
        setIsLoadingAppUsers(true);
        dispatch(fetchAppUsers({page,perPage,searchData:_searchData})).then(async response =>{
            setIsLoadingAppUsers(false);
            if(response.data.error === false){
                setTotalAppUsers(response.data.total_app_users)
                setPageCount(response.data.pageCount)
            }
        });
    }

    useEffect(() => {
        loadMoreAppUsers(page,perPage,searchData);
        setIsFetchingAllOrganizations(true);
        dispatch(fetchAllOrganizations()).then(async response => {
            setIsFetchingAllOrganizations(false);
            const formattedOrganizationsArr = response.data.formattedOrganizationsArr;
            setFormatedOrganizationDrpOptions(formattedOrganizationsArr)
        });
        // componentWillUnmount 
        return () => {
            dispatch(clearAppUsers())
        }
    }, []);
    const btnOnClickEditUser = (objUser) => {
        console.log('objUser in btnOnClickEditUser')
        console.log('objUser in btnOnClickEditUser')
        console.log(objUser)
        setShowEditAppUserModal(true);
        setSelectedEditObjUser(objUser);
    };
    const btnOnClickDeleteUser = (objUser) => {
        console.log('objUser in btnOnClickDeleteUser')
        console.log('objUser in btnOnClickDeleteUser')
        console.log(objUser)
        let conf = window.confirm('Are you sure?');
        if(!conf){
            return false;
        }
        dispatch(deleteAppUser(objUser._id)).then( response =>{
            if(response.data.error === false){
                showMsg('success',`You have deleted the user`)
                dispatch(deleteAppUserFromAppUserListTbl(objUser._id));
            }else{
                showMsg('error',response.data.msg)
            }
        });
        // setShowEditAppUserModal(true);
        // setSelectedEditObjUser(objUser);
    };

    const resetFilters = (_page,_total_app_users) =>{
        setPage(_page);
        setTotalAppUsers(_total_app_users);
        dispatch(clearAppUsers());
    }
    const btnOnClickSearch = (objFormValus) => {
        console.log('objFormValus')
        console.log('objFormValus')
        console.log('objFormValus')
        console.log(objFormValus)
        const _page = 1;
        const _total_app_users = 0;
        setSearchData(objFormValus)
        resetFilters(_page,_total_app_users);
        loadMoreAppUsers(_page,perPage,objFormValus);
    }
    const btnOnClickReset = () => {
        const _page = 1;
        const _total_app_users = 0;
        setSearchData(initialValues)
        resetFilters(_page,_total_app_users);
        loadMoreAppUsers(_page,perPage,initialValues);
    }
    console.log('objAppUsers in render')
    console.log('objAppUsers in render')
    console.log('objAppUsers in render')
    console.log('objAppUsers in render')
    console.log(objAppUsers)
    return (
        <div className='HomePage'>
            
            <div className='bookings-listings-box'>
                <AppUsersListSearchForm 
                    btnOnClickSearch={btnOnClickSearch}
                    btnOnClickReset={btnOnClickReset}
                    initialValues={initialValues}
                />
                <h3>Admin Users List</h3>
                <Table>
                    <thead>
                        <tr>
                            <th>Details</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {
                        objAppUsers !== undefined ?
                        <tbody>
                            <AppUsersLoadUserList
                                isLoadingAppUsers={isLoadingAppUsers}
                                objAppUsers={objAppUsers}
                                total_app_users={total_app_users}
                                btnOnClickEditUser={btnOnClickEditUser}
                                btnOnClickDeleteUser={btnOnClickDeleteUser}
                            />
                        </tbody>
                        : null
                    }
                </Table>
                {
                    isLoadingAppUsers ? <LoadingSpinner subClass="text-center" color="lightblueColor" size="lg"  /> :null
                }
                <ReactPaginate
                    // previousLabel={<ChevronLeft size={15} />}
                    // nextLabel={<ChevronRight size={15} />}
                    previousLabel={<i className="bx bx-chevrons-left"></i>}
                    nextLabel={<i className="bx bx-chevrons-right"></i>}
                    nextClassName="next page-numbers"
                    previousClassName="prev page-numbers"
                    pageClassName="page-numbers"
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    forcePage={page -1} 
                    containerClassName={
                    "pagination-area text-center mt-3 mb-3"
                    }
                    activeClassName={"current"}
                />
            </div>
            <EditAppUserModal 
                showEditAppUserModal={showEditAppUserModal}
                setShowEditAppUserModal={setShowEditAppUserModal}
                selectedEditObjUser={selectedEditObjUser}
                formatedOrganizationDrpOptions={formatedOrganizationDrpOptions}
            />
            <div className='flex-grow-1'></div>

        </div>
    )
}
export default AppUsersList

import axios from "axios"
import { BASE_URL, ADMIN_USERS_SERVER } from "../../../components/utils/constants/misc"
import { 
    ADMIN_AUTH_USER,
    LOGOUT_ADMIN_USER,
    UPDATE_ADMIN_AUTH_USER,
    // RE_SEND_VERIFICATION_EMAIL_TO_USER,
} from '../../actions/types';

export const adminAuth = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}${ADMIN_USERS_SERVER}/auth`);
    dispatch({ type: ADMIN_AUTH_USER, payload: res.data });
    return Promise.resolve(res);
};
export const logoutAdminUser = () => async dispatch => {
    const res = await axios.post(`${BASE_URL}${ADMIN_USERS_SERVER}/logout`,{});
    localStorage.removeItem('token')
    axios.defaults.headers.common['Authorization'] = '';
    dispatch({ type: LOGOUT_ADMIN_USER, payload: res.data });
    return Promise.resolve(res);
}

export const updateAdminAuthUser = (values) => dispatch => {
    let objAdminUser = {
        isAuth: true,
        _id: values._id,
        email: values.email,
        full_name:values.full_name,
        username:values.username,
        profile_pic:values.profile_pic,
        is_comps_notification_on:values.is_comps_notification_on,
        stripe_is_subscription_active:values.stripe_is_subscription_active,
        stripe_is_subscription_cancelled_by_webhook:values.stripe_is_subscription_cancelled_by_webhook,
        stripe_customer_id:values.stripe_customer_id,
        protest_credits:values.protest_credits,
        phone_no:values.phone_no,
        is_email_verified:values.is_email_verified,
        is_user_has_already_purchased_one_property:values.is_user_has_already_purchased_one_property, 
    };
    // console.log('objAdminUser in updateAdminAuthUser')
    // console.log('objAdminUser in updateAdminAuthUser')
    // console.log('objAdminUser in updateAdminAuthUser')
    // console.log(objAdminUser)
    dispatch({ type: UPDATE_ADMIN_AUTH_USER, payload: objAdminUser });
}


import {
    SAVE_ORGANIZATION,
    FETCH_ORGANIZATIONS,
    CLEAR_ORGANIZATIONS,
    DELETE_ORGANIZATION,
    DELETE_ORGANIZATION_FROM_ADMIN_ORGANIZATION_LIST_TBL,
    UPDATE_ORGANIZATION,
    ADD_UPDATE_ORGANIZATION_IN_ORGANIZATION_LIST_TBL,
    FETCH_ALL_ORGANIZATIONS,
} from '../../actions/types';

export const organizations = (state = {}, action) => {
    switch (action.type) {
        case SAVE_ORGANIZATION: {
            return { ...state, objCreateAdminOrganizationSuccess: action.payload }
        }
        case FETCH_ORGANIZATIONS: {
            console.log('state.objOrganizations in store')
            console.log('state.objOrganizations in store')
            console.log(state.objOrganizations)
            if (state.objOrganizations === undefined) {
                return { ...state, objOrganizations: action.payload }
            } else {
                return {
                    ...state,
                    objOrganizations: {
                        ...state.objOrganizations,
                        error: action.payload.error,
                        msg: action.payload.msg,
                        total_organizations: action.payload.total_organizations,
                        // objOrganizations:action.payload.objOrganizations,
                        objOrganizations: [...state.objOrganizations.objOrganizations, ...action.payload.objOrganizations]
                    }
                }
            }
        }

        case CLEAR_ORGANIZATIONS: {
            return { ...state, objOrganizations: action.payload }
        }
        case DELETE_ORGANIZATION: {
            return { ...state, objDeletOrganizationSuccess: action.payload }
        }
        case DELETE_ORGANIZATION_FROM_ADMIN_ORGANIZATION_LIST_TBL: {
            let user_id = action.payload;
            let objOrganizations = state.objOrganizations;
            let items = state.objOrganizations.objOrganizations.slice();
            let total_organizations = state.objOrganizations.total_organizations;
            const itemIndex = items.findIndex(item => item._id === user_id);
            console.log('itemIndex')
            console.log(itemIndex)
            console.log('total_organizations')
            console.log(total_organizations)
            // add new one
            if (itemIndex !== -1) {
                const nextState = items.filter(item => item._id !== user_id)
                console.log('nextState')
                console.log('nextState')
                console.log(nextState)
                total_organizations = total_organizations - 1;
                console.log('total_organizations after')
                console.log('total_organizations after')
                console.log(total_organizations)
                return {
                    ...state,
                    objOrganizations: {
                        ...objOrganizations,
                        objOrganizations: nextState,
                        total_organizations,
                    },
                }
            } else {
                return state;
            }
        }

        case UPDATE_ORGANIZATION: {
            return { ...state, objUpdateAdminUserFormSuccess: action.payload }
        }

        case ADD_UPDATE_ORGANIZATION_IN_ORGANIZATION_LIST_TBL: {
            let objAdminOrganization = action.payload;
            console.log('objAdminOrganization in ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
            console.log('objAdminOrganization in ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
            console.log(objAdminOrganization)
            let objOrganizations = state.objOrganizations;
            let items = state.objOrganizations.objOrganizations.slice();
            console.log('items in ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
            console.log('items in ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
            console.log(items)
            let total_organizations = state.objOrganizations.total_organizations;
            const itemIndex = items.findIndex(item => item._id === objAdminOrganization._id);
            console.log('itemIndex in ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
            console.log('itemIndex in ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
            console.log(itemIndex)
            // add new one
            if (itemIndex === -1) {
                console.log('I am in if of ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
                console.log('I am in if of ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
                items.unshift(objAdminOrganization)
                // items.push(objSignal)
                total_organizations = total_organizations + 1;
                return {
                    ...state,
                    objOrganizations: {
                        ...objOrganizations,
                        objOrganizations: items,
                        total_organizations,
                    },
                }
            } else {
                console.log('I am in else of ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
                console.log('I am in else of ADD_UPDATE_ADMIN_CURRENT_LIMIT_ORDER')
                // update the existing one
                let objOrganizations = state.objOrganizations.objOrganizations.slice();
                const nextStateObjOrganizations = objOrganizations.map(item => {
                    console.log('item._id')
                    console.log('item._id')
                    console.log(item._id)
                    console.log('objAdminOrganization._id')
                    console.log('objAdminOrganization._id')
                    console.log(objAdminOrganization._id)
                    if (item._id !== objAdminOrganization._id) {
                        // not our item, return it as is
                        return item;
                    }
                    console.log("Item found ")
                    console.log("Item found ")
                    console.log("Item found ")
                    // this is our relevant item, return a new copy of it with modified fields
                    return {
                        // ...item,
                        ...objAdminOrganization,
                    }
                });
                return {
                    ...state,
                    objOrganizations: {
                        ...objOrganizations,
                        objOrganizations: nextStateObjOrganizations,
                    },
                }
            }
        }

        case FETCH_ALL_ORGANIZATIONS: {
            return { ...state, objFetchAllOrganizationsData: action.payload }
        }
        
        
        default: {
            return state
        }
    }
}

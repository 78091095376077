import React, { Fragment } from "react";
import AdminUsersUsersListItemBlock from './AdminUsersUsersListItemBlock'
// import LoadingSpinner from "../../../../../components/helpers/LoadingSpinner"
const AdminUsersLoadUserList = (props) => {
  
  return (
    <Fragment>
        <AdminUsersUsersListItemBlock
          list={props.objAdminUsers.objAdminUsers}
          isLoadingAdminUsers={props.isLoadingAdminUsers}
          btnOnClickEditUser={props.btnOnClickEditUser}
          btnOnClickDeleteUser={props.btnOnClickDeleteUser}
        />
    </Fragment>
  )
}
export default AdminUsersLoadUserList

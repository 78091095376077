import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, 
    // Card,
} from 'reactstrap';
import EditAdminUserModalBody from './EditAdminUserModalBody';
const EditAdminUserModal = (props) => {
    const {
        // buttonLabel,
        showEditAdminUserModal,setShowEditAdminUserModal,
        selectedEditObjUser
    } = props;
    const toggle = () => {
        setShowEditAdminUserModal(!showEditAdminUserModal);
    };
   
    // const btnOnClickClosePosition = () =>{
    //     toggle();
    // }

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal backdrop={false} size="lg" isOpen={showEditAdminUserModal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Edit {selectedEditObjUser.email}</ModalHeader>
                <ModalBody>
                    <div className='add-listings-box'>
                        <EditAdminUserModalBody 
                            selectedEditObjUser={selectedEditObjUser}
                            toggle={toggle}
                        />
                    </div>
                    {/* <Card className="SignalsSection">
                        <CardHeader>
                            <CardTitle></CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className='add-listings-box'>
                                <EditAdminUserModalBody 
                                    selectedEditObjUser={selectedEditObjUser}
                                    toggle={toggle}
                                />
                            </div>
                        </CardBody>
                    </Card> */}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Exit</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditAdminUserModal;
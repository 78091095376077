export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const S3_URL = 'https://cr.s3.amazonaws.com/'
export const MAIN_APP_BASE_URL = process.env.REACT_APP_MAIN_APP_BASE_URL;

export const ADMIN_USERS_SERVER = '/api/admin/admin-users';
export const APP_USERS_SERVER = '/api/admin/app-users';

export const USER_ACCOUNT_SERVER = '/api/users-account';
export const ADMIN_ORGANIZATION_SERVER = '/api/admin/organizations';



export const SETTINGS_SERVER = '/api/settings';


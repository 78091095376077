import { combineReducers } from "redux"
// import { adminRegister } from "./adminRegisterReducers";
import { adminLogin } from "./adminLoginReducers";
import { adminUser } from "./adminUserReducers"
// import { userSettings } from "./userSettingsReducers"
import { adminUserAccount } from "./adminUserAccountReducers"
// import { signupFunel } from "./signupFunelReducers"
// import { userFunnelAccount } from "./userFunnelAccountReducers"
// import { userSubscription } from "./userSubscriptionReducers"

const authReducers = combineReducers({
    // adminRegister,
    adminLogin,
    adminUserAccount,
    adminUser,
    // userSettings,
    // userAccount,
    
})

export default authReducers
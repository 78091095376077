import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, 
    // Card,
} from 'reactstrap';
import EditAppUserModalBody from './EditAppUserModalBody';
const EditAppUserModal = (props) => {
    const {
        // buttonLabel,
        showEditAppUserModal,setShowEditAppUserModal,
        selectedEditObjUser, formatedOrganizationDrpOptions
    } = props;
    const toggle = () => {
        setShowEditAppUserModal(!showEditAppUserModal);
    };
   
    // const btnOnClickClosePosition = () =>{
    //     toggle();
    // }

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal backdrop={false} size="lg" isOpen={showEditAppUserModal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Edit {selectedEditObjUser.email}</ModalHeader>
                <ModalBody>
                    <div className='add-listings-box'>
                        <EditAppUserModalBody 
                            selectedEditObjUser={selectedEditObjUser}
                            toggle={toggle}
                            formatedOrganizationDrpOptions={formatedOrganizationDrpOptions}
                        />
                    </div>
                    
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Exit</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditAppUserModal;
import React, { useRef } from 'react';
import loginImgPng from '../../../assets/images/auth/login-image.png';
import { Row, Col } from 'reactstrap';
import LoginForm from './components/LoginForm';
import queryString from 'query-string';
const objInitialValues = {
    email: "",
    password: "",
}
const Login = () => {
    const formRef = useRef();
    const urlParams = queryString.parse(window.location.search);
    console.log('urlParams in Login')
    console.log('urlParams in Login')
    console.log('urlParams in Login')
    console.log(urlParams)
    return (
        <>
            <div className="inner-wrapper loginPage">
                <div className="container-fluid no-padding">
                    {/* In Bootstrap 5 no-gutters === g-0 so we can remove this no-gutters class but I will remove it latter*/}
                    <Row className="g-0 no-gutters overflow-auto">
                        <Col lg="6" md="6" sm="12" className="hideOnMobile">
                            <div className="main-banner">
                                <img src={loginImgPng} className="img-fluid full-width main-img" alt="banner" />
                            </div>
                        </Col>
                        <Col lg="6" md="6" sm="12" className="bg-login-page">
                            <div className="section-2 user-page main-padding">
                                <div className="login-sec">
                                    <div className="login-box">
                                        <LoginForm 
                                            objInitialValues={objInitialValues}
                                            formRef={formRef}
                                            urlParams={urlParams}
                                        />
                                        {/* <NoAuthLayoutFooter /> */}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default Login;

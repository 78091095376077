import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { Row, Col, Label, FormGroup, Button } from 'reactstrap';
import { useDispatch } from "react-redux";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import LoadingSpinner from '../../../../utils/Helpers/Loading/LoadingSpinner';
import { useNavigate } from "react-router-dom";
import { adminLoginUser } from '../../../../redux/actions/adminAuth/adminLoginActions';

import { showMsg } from '../../../../utils/general';
// import { FaFacebook,FaGoogle } from 'react-icons/fa';
const formLoginJWTSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Email is Required"),
    password: Yup.string()
      .min(8, "Password is too short!(min 8 characters are required)")
      .required("Password is required"),
})
const LoginForm = (props) => {
    const { 
        objInitialValues, formRef, 
        urlParams,
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isGenerateResendLink, setGenerateResendLink] = useState(false);
    const [isSendingResendVerificationLink, setIsSendingResendVerificationLink] = useState(false);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
   
    const onSubmitForm = async (values) => {
        console.log('values in onSubmitForm')
        console.log(values)
        setIsLoading(true);
        setGenerateResendLink(false)
        dispatch(adminLoginUser(values)).then(async response =>{
            setIsLoading(false);
            const data = response.data;
            console.log('response.data')
            console.log('response.data')
            console.log('response.data')
            console.log(data)
            if(response.data.error === false){
                showMsg('success',response.data.msg)
                axios.defaults.headers.common['Authorization'] = response.data.token;
                localStorage.setItem('token', response.data.token);
                // navigate.push(`/login`);
                let redirectUrl = '/app/users/list';
                
                console.log('redirectUrl in loginUser')
                console.log('redirectUrl in loginUser')
                console.log(redirectUrl)
                navigate(redirectUrl);
            }else{
                showMsg('error',response.data.msg)
            }
        });
    };
    return (
        <Formik
            innerRef={formRef}
            initialValues={objInitialValues}
            validationSchema={formLoginJWTSchema}
            onSubmit={onSubmitForm}
        >
            {({ errors, touched }) => (
                <Form>
                    <h4 className="text-theme fw-600 fs-32 mb-30">Sign In</h4>
                    <Row>
                        <Col>
                            <FormGroup className={`${errors.email && touched.email && "text-danger"}`}>
                                <Label className="text-theme">Email Address</Label>
                                <Field
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    className={`form-control ${errors.email &&
                                        touched.email &&
                                        "is-invalid"}`}
                                />
                                
                                {errors.email && touched.email ? (
                                    <div className="invalid-feedback">{errors.email}</div>
                                ) : null}
                            </FormGroup>
                            <FormGroup >
                                <Label className="text-theme" for="password">Password</Label>
                                {/* <input type="password" id="password" name="password" className="form-control"  placeholder="Enter Your Password" required /> */}
                                <Field
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    className={`form-control ${errors.password &&
                                        touched.password &&
                                        "is-invalid"}`}
                                />
                                {errors.password && touched.password ? (
                                    <div className="invalid-feedback">{errors.password}</div>
                                ) : null}
                            </FormGroup>
                            
                            
                            
                            <FormGroup className="mt-30">
                                
                                {
                                    isLoading === false ?
                                    <Button className="btn-second btn-submit full-width btn btn-success btnLogin">
                                        Login
                                    </Button>
                                    : 
                                    <LoadingSpinner subClass="text-center" color="lightblueColor" size="lg" />
                                }
                            </FormGroup>
                            
                          
                            {/* <FormGroup className="text-center text-theme fs-14"> 
                                <a href="https://websitename.com" 
                                    target='_blank'
                                    rel="noopener"
                                >
                                    <span className="text-light-green"> Click here</span>
                                </a> to go back to home page
                            </FormGroup> */}
                            
                                
                            
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;

import { useState, useContext } from 'react';
// import Link from '../../utils/ActiveLink';
import { Link } from 'react-router-dom';
import { IndiceContext } from "../contexts";

const NavbarThree = (props) => {
    const {objUser} = props;
    const { toggleSideMenu } = useContext(IndiceContext);
    const [showMenu, setshowMenu] = useState(false);
    const [displayMiniAuth, setDisplayMiniAuth] = useState(false);
    const [displayDropdownProfile, setDisplayDropdownProfile] = useState(false);

    const toggleMiniAuth = () => {
        setDisplayMiniAuth(!displayMiniAuth);
    };

    const toggleMenu = () => {
        setshowMenu(!showMenu);
    };

    const toggleDropdownProfile = () => {
        setDisplayDropdownProfile(!displayDropdownProfile);
    };
    console.log('objUser in NavbarThree');
    console.log('objUser in NavbarThree');
    console.log('objUser in NavbarThree');
    console.log(objUser);
    return (
        <>
            <div className='navbar-area'>
                <div className='miran-responsive-nav'>
                    <div className='miran-responsive-menu'>
                        <div
                            onClick={() => toggleMenu()}
                            className='hamburger-menu hamburger-two dashboard-hamburger'
                        >
                            {showMenu ? (
                                <i className='bx bx-x'></i>
                            ) : (
                                <i className='bx bx-menu'></i>
                            )}
                        </div>
                        <div className='responsive-burger-menu d-lg-none d-block' onClick={toggleSideMenu}>
                            <span className='top-bar'></span>
                            <span className='middle-bar'></span>
                            <span className='bottom-bar'></span>
                        </div>
                    </div>
                </div>

                <div className={showMenu ? 'miran-nav show' : 'miran-nav'}>
                    <nav className='navbar navbar-expand-md navbar-light'>
                        <div className='collapse navbar-collapse mean-menu'>
                            {/* <ul className='navbar-nav'>
                                <li className='nav-item'>
                                    <a href='#' className='dropdown-toggle nav-link'>
                                        Home
                                    </a>
                                    <ul className='dropdown-menu'>
                                        <li className='nav-item'>
                                            <Link to='/' >
                                                <a className='nav-link'>Home Demo - 1</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/index-2' >
                                                <a className='nav-link'>Home Demo - 2</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/index-3' >
                                                <a className='nav-link'>Home Demo - 3</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/index-4' >
                                                <a className='nav-link'>Home Demo - 4</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className='nav-item'>
                                    <a href='#' className='dropdown-toggle nav-link'>
                                        Listings
                                    </a>
                                    <ul className='dropdown-menu'>
                                        <li className='nav-item'>
                                            <a href='#' className='nav-link'>
                                                List Layout <i className='bx bx-chevron-right'></i>
                                            </a>
                                            <ul className='dropdown-menu'>
                                                <li className='nav-item'>
                                                    <Link
                                                        to='/vertical-listings-left-sidebar'
                                                        
                                                    >
                                                        <a className='nav-link'>Left Sidebar</a>
                                                    </Link>
                                                </li>

                                                <li className='nav-item'>
                                                    <Link
                                                        to='/vertical-listings-right-sidebar'
                                                        
                                                    >
                                                        <a className='nav-link'>Right Sidebar</a>
                                                    </Link>
                                                </li>

                                                <li className='nav-item'>
                                                    <Link
                                                        to='/vertical-listings-full-width'
                                                        
                                                    >
                                                        <a className='nav-link'>Full Width</a>
                                                    </Link>
                                                </li>

                                                <li className='nav-item'>
                                                    <Link
                                                        to='/vertical-listings-with-map'
                                                        
                                                    >
                                                        <a className='nav-link'>Full Width + Map</a>
                                                    </Link>
                                                </li>

                                                <li className='nav-item'>
                                                    <Link
                                                        to='/vertical-listings-full-map'
                                                        
                                                    >
                                                        <a className='nav-link'>Full Width + Full Map</a>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className='nav-item'>
                                            <a href='#' className='nav-link'>
                                                Grid Layout <i className='bx bx-chevron-right'></i>
                                            </a>
                                            <ul className='dropdown-menu'>
                                                <li className='nav-item'>
                                                    <Link
                                                        to='/grid-listings-with-left-sidebar'
                                                        
                                                    >
                                                        <a className='nav-link'>Left Sidebar</a>
                                                    </Link>
                                                </li>

                                                <li className='nav-item'>
                                                    <Link
                                                        to='/grid-listings-with-right-sidebar'
                                                        
                                                    >
                                                        <a className='nav-link'>Right Sidebar</a>
                                                    </Link>
                                                </li>

                                                <li className='nav-item'>
                                                    <Link
                                                        to='/grid-listings-full-width'
                                                        
                                                    >
                                                        <a className='nav-link'>Full Width</a>
                                                    </Link>
                                                </li>

                                                <li className='nav-item'>
                                                    <Link
                                                        to='/grid-listings-with-map'
                                                        
                                                    >
                                                        <a className='nav-link'>Full Width + Map</a>
                                                    </Link>
                                                </li>

                                                <li className='nav-item'>
                                                    <Link
                                                        to='/grid-listings-full-map'
                                                        
                                                    >
                                                        <a className='nav-link'>Full Width + Full Map</a>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/single-listings' >
                                                <a className='nav-link'>Listings Details</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/destinations' >
                                                <a className='nav-link'>Top Place</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/categories' >
                                                <a className='nav-link'>Categories</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/user-profile' >
                                                <a className='nav-link'>Author Profile</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className='nav-item'>
                                    <a href='#' className='dropdown-toggle nav-link active'>
                                        User Panel
                                    </a>
                                    <ul className='dropdown-menu'>
                                        <li className='nav-item'>
                                            <Link to='/dashboard' >
                                                <a className='nav-link'>Dashboard</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link
                                                to='/dashboard/messages'
                                                
                                            >
                                                <a className='nav-link'>Messages</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link
                                                to='/dashboard/bookings'
                                                
                                            >
                                                <a className='nav-link'>Bookings</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/dashboard/wallet' >
                                                <a className='nav-link'>Wallet</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link
                                                to='/dashboard/my-listing/active'
                                                
                                            >
                                                <a className='nav-link'>My Listings</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link
                                                to='/dashboard/reviews'
                                                
                                            >
                                                <a className='nav-link'>Reviews</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link
                                                to='/dashboard/bookmarks'
                                                
                                            >
                                                <a className='nav-link'>Bookmarks</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link
                                                to='/dashboard/add-listing'
                                                
                                            >
                                                <a className='nav-link'>Add Listings</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link
                                                to='/dashboard/profile'
                                                
                                            >
                                                <a className='nav-link'>My Profile</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link
                                                to='/dashboard/invoice'
                                                
                                            >
                                                <a className='nav-link'>Invoice</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className='nav-item'>
                                    <a href='#' className='dropdown-toggle nav-link'>
                                        Shop
                                    </a>
                                    <ul className='dropdown-menu'>
                                        <li className='nav-item'>
                                            <Link to='/shop' >
                                                <a className='nav-link'>Products List</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/cart' >
                                                <a className='nav-link'>Cart</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/checkout' >
                                                <a className='nav-link'>Checkout</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/product-details' >
                                                <a className='nav-link'>Products Details</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className='nav-item'>
                                    <a href='#' className='dropdown-toggle nav-link'>
                                        Blog
                                    </a>
                                    <ul className='dropdown-menu'>
                                        <li className='nav-item'>
                                            <Link to='/blog-1' >
                                                <a className='nav-link'>Grid (2 in Row)</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/blog-2' >
                                                <a className='nav-link'>Grid (3 in Row)</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/blog-3' >
                                                <a className='nav-link'>Grid (Full Width)</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/blog-4' >
                                                <a className='nav-link'>Right Sidebar</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/blog-5' >
                                                <a className='nav-link'>Left Sidebar</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <a href='#' className='nav-link'>
                                                Single Post <i className='bx bx-chevron-right'></i>
                                            </a>
                                            <ul className='dropdown-menu'>
                                                <li className='nav-item'>
                                                    <Link
                                                        to='/single-blog-1'
                                                        
                                                    >
                                                        <a className='nav-link'>Default</a>
                                                    </Link>
                                                </li>

                                                <li className='nav-item'>
                                                    <Link
                                                        to='/single-blog-2'
                                                        
                                                    >
                                                        <a className='nav-link'>With Video</a>
                                                    </Link>
                                                </li>

                                                <li className='nav-item'>
                                                    <Link
                                                        to='/single-blog-3'
                                                        
                                                    >
                                                        <a className='nav-link'>With Image Slider</a>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                <li className='nav-item'>
                                    <a href='#' className='dropdown-toggle nav-link'>
                                        Pages
                                    </a>
                                    <ul className='dropdown-menu'>
                                        <li className='nav-item'>
                                            <Link to='/about' >
                                                <a className='nav-link'>About Us</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/how-it-works' >
                                                <a className='nav-link'>How It Work</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/pricing' >
                                                <a className='nav-link'>Pricing</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/gallery' >
                                                <a className='nav-link'>Gallery</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <a href='#' className='nav-link'>
                                                Events <i className='bx bx-chevron-right'></i>
                                            </a>
                                            <ul className='dropdown-menu'>
                                                <li className='nav-item'>
                                                    <Link to='/events' >
                                                        <a className='nav-link'>Events</a>
                                                    </Link>
                                                </li>

                                                <li className='nav-item'>
                                                    <Link
                                                        to='/single-events'
                                                        
                                                    >
                                                        <a className='nav-link'>Events Details</a>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/testimonial' >
                                                <a className='nav-link'>Testimonials</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/faq' >
                                                <a className='nav-link'>FAQ</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/404' >
                                                <a className='nav-link'>404 Error</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/coming-soon' >
                                                <a className='nav-link'>Coming Soon</a>
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link to='/contact' >
                                                <a className='nav-link'>Contact</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul> */}

                            <div className='others-option d-flex align-items-center'>
                                <div className='option-item'>
                                    Hello Admin
                                </div>
                                {/* <div className='option-item'>
                                    <div className='dropdown profile-nav-item menu-profile-one'>
                                        <a
                                            href='#'
                                            className='dropdown-toggle'
                                            role='button'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            <div className='menu-profile'>
                                                <img
                                                    src='/images/user1.jpg'
                                                    className='rounded-circle'
                                                    alt='image'
                                                />
                                                <span className='name' onClick={toggleDropdownProfile}>My Account</span>
                                            </div>
                                        </a>

                                        <div className={
                                            displayDropdownProfile
                                                ? 'dropdown-menu show'
                                                : 'dropdown-menu'
                                        }>

                                            <div className='dropdown-header d-flex flex-column align-items-center'>
                                                <div className='figure mb-3'>
                                                    <img
                                                        src='/images/user1.jpg'
                                                        className='rounded-circle'
                                                        alt='image'
                                                    />
                                                </div>

                                                <div className='info text-center'>
                                                    <span className='name'>Andy Smith</span>
                                                    <p className='mb-3 email'>hello@androsmith.com</p>
                                                </div>
                                            </div>

                                            <div className='dropdown-body'>
                                                <ul className='profile-nav p-0 pt-3'>
                                                    <li className='nav-item'>
                                                        <Link to="/dashboard">

                                                            <a className='nav-link'>
                                                                <i className='bx bx-user'></i> <span>Dashboard</span>
                                                            </a>
                                                        </Link>
                                                    </li>


                                                    <li className='nav-item'>

                                                        <a href='dashboard-messages.html' className='nav-link'>
                                                            <i className='bx bx-envelope'></i><span>Messages</span>
                                                        </a>

                                                    </li>

                                                    <li className='nav-item'>
                                                        <a href='dashboard-bookings.html' className='nav-link'>
                                                            <i className='bx bx-edit-alt'></i>{' '}
                                                            <span>Bookings</span>
                                                        </a>
                                                    </li>

                                                    <li className='nav-item'>
                                                        <a
                                                            href='dashboard-my-profile.html'
                                                            className='nav-link'
                                                        >
                                                            <i className='bx bx-cog'></i> <span>Settings</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className='dropdown-footer'>
                                                <ul className='profile-nav'>
                                                    <li className='nav-item'>
                                                        <a href='index.html' className='nav-link'>
                                                            <i className='bx bx-log-out'></i> <span>Logout</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className='option-item'>
                                    <a
                                        href='dashboard-add-listings.html'
                                        className='default-btn button-one'
                                    >
                                        <i className='flaticon-more'></i> Add Listing
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </nav>
                </div>

                {/* <div className='others-option-for-responsive'>
                    <div className='container'>
                        <div className='dot-menu' onClick={toggleMiniAuth}>
                            <div className='inner'>
                                <div className='circle circle-one'></div>
                                <div className='circle circle-two'></div>
                                <div className='circle circle-three'></div>
                            </div>
                        </div>

                        <div className={displayMiniAuth ? 'container active' : 'container'}>
                            <div className='option-inner'>
                                <div className='others-option'>
                                    <div className='option-item'>
                                        <div className='dropdown profile-nav-item'>
                                            <a
                                                href='#'
                                                className='dropdown-toggle'
                                                role='button'
                                                data-toggle='dropdown'
                                                aria-haspopup='true'
                                                aria-expanded='false'
                                            >
                                                <div className='menu-profile'>
                                                    <img
                                                        src='/images/user1.jpg'
                                                        className='rounded-circle'
                                                        alt='image'
                                                    />
                                                    <span className='name' onClick={toggleDropdownProfile}>
                                                        My Account
                                                    </span>
                                                </div>
                                            </a>

                                            <div
                                                className={
                                                    displayDropdownProfile
                                                        ? 'dropdown-menu show'
                                                        : 'dropdown-menu'
                                                }
                                            >
                                                <div className='dropdown-header d-flex flex-column align-items-center'>
                                                    <div className='figure mb-3'>
                                                        <img
                                                            src='/images/user1.jpg'
                                                            className='rounded-circle'
                                                            alt='image'
                                                        />
                                                    </div>

                                                    <div className='info text-center'>
                                                        <span className='name'>Andy Smith</span>
                                                        <p className='mb-3 email'>hello@androsmith.com</p>
                                                    </div>
                                                </div>

                                                <div className='dropdown-body'>
                                                    <ul className='profile-nav p-0 pt-3'>
                                                        <li className='nav-item'>
                                                            <a href='dashboard.html' className='nav-link'>
                                                                <i className='bx bx-user'></i>{' '}
                                                                <span>Dashboard</span>
                                                            </a>
                                                        </li>

                                                        <li className='nav-item'>
                                                            <a
                                                                href='dashboard-messages.html'
                                                                className='nav-link'
                                                            >
                                                                <i className='bx bx-envelope'></i>{' '}
                                                                <span>Messages</span>
                                                            </a>
                                                        </li>

                                                        <li className='nav-item'>
                                                            <a
                                                                href='dashboard-bookings.html'
                                                                className='nav-link'
                                                            >
                                                                <i className='bx bx-edit-alt'></i>{' '}
                                                                <span>Bookings</span>
                                                            </a>
                                                        </li>

                                                        <li className='nav-item'>
                                                            <a
                                                                href='dashboard-my-profile.html'
                                                                className='nav-link'
                                                            >
                                                                <i className='bx bx-cog'></i> <span>Settings</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className='dropdown-footer'>
                                                    <ul className='profile-nav'>
                                                        <li className='nav-item'>
                                                            <a href='index.html' className='nav-link'>
                                                                <i className='bx bx-log-out'></i>{' '}
                                                                <span>Logout</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='option-item'>
                                        <a href='dashboard-add-listings.html' className='default-btn'>
                                            <i className='flaticon-more'></i> Add Listing
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default NavbarThree;

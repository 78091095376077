import React, { Fragment } from "react";
import OrganizationsListItemBlock from './OrganizationsListItemBlock'
// import LoadingSpinner from "../../../../../components/helpers/LoadingSpinner"
const LoadOrganizationsList = (props) => {
  
  return (
    <Fragment>
        <OrganizationsListItemBlock
          list={props.objOrganizations.objOrganizations}
          isLoadingOrganizations={props.isLoadingOrganizations}
          btnOnClickEditFrame={props.btnOnClickEditFrame}
          btnOnClickDeleteFrame={props.btnOnClickDeleteFrame}
        />
    </Fragment>
  )
}
export default LoadOrganizationsList

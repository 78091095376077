import { toast } from "react-toastify";
import { S3_URL } from "../components/utils/constants/misc";
export function showMsg(type,msg,autoClose = 8000) {
    if(type === 'success'){
        toast.success(msg,{autoClose});
    }else if(type === 'error'){
        toast.error(msg,{autoClose});
    }else if(type === 'info'){
        toast.info(msg,{autoClose});
    }
}

export function getS3Url(filePath){
    const s3_url  = `${S3_URL}${filePath}`;
    return s3_url;
}


import React, { Fragment } from "react"
import AppUsersListTr from './AppUsersListTr';
const AppUsersListItemBlock = (props) => {
    const renderAppUsersList = () => (
        props.list ?
            props.list.map(objAppUser => (
                <AppUsersListTr
                    key={objAppUser._id}
                    {...objAppUser}
                    btnOnClickEditUser={props.btnOnClickEditUser}
                    btnOnClickDeleteUser={props.btnOnClickDeleteUser}
                />
            ))
            : null
    )
    return (
        <Fragment>
            {props.list ?
                props.list.length === 0 && props.isLoadingAppUsers === false ?
                    <tr className="text-center">
                        <td className="text-center" colSpan="10"> No records found.</td>
                    </tr>
                    : null
                : null}
            {renderAppUsersList(props.list)}
        </Fragment>
    )
}
export default AppUsersListItemBlock

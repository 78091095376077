import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { Row, Col, Label, FormGroup, Button } from 'reactstrap';
import { useDispatch } from "react-redux";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import LoadingSpinner from '../../../../utils/Helpers/Loading/LoadingSpinner';
import { useNavigate } from "react-router-dom";
import Select from "react-select"
import makeAnimated from "react-select/animated";
import { showMsg } from '../../../../utils/general';
import { adminCreateNewAppUser } from '../../../../redux/actions/appUserAuth/appUserAccountActions';
// import { FaFacebook,FaGoogle } from 'react-icons/fa';
const animatedComponents = makeAnimated();
const addAdminUserJWTSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is Required"),
    password: Yup.string()
      .min(8, "Password is too short!(min 8 characters are required)")
      .required("Password is required"),
})
const AddAppUsersForm = (props) => {
    const { 
        objInitialValues, formatedOrganizationDrpOptions, selectedObjOrganization,
        setSelectedObjOrganization
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
   
    const onSubmitForm = async (values) => {
        console.log('values in onSubmitForm')
        console.log(values)
        console.log('selectedObjOrganization in onSubmitForm')
        console.log(selectedObjOrganization)
        console.log('Object.keys(selectedObjOrganization).length in onSubmitForm')
        console.log(Object.keys(selectedObjOrganization).length)
        if(Object.keys(selectedObjOrganization).length > 0){
            setIsLoading(true);
            let objFormData = {organization_id:selectedObjOrganization._id,...values}
            console.log('objFormData');
            console.log(objFormData);
            dispatch(adminCreateNewAppUser(objFormData)).then(async response =>{
                setIsLoading(false);
                const data = response.data;
                console.log('response.data')
                console.log('response.data')
                console.log('response.data')
                console.log(data)
                if(response.data.error === false){
                    showMsg('success',response.data.msg);
                    navigate('/app/users/list');
                }else{
                    showMsg('error',response.data.msg)
                }
            });
        }else{
            showMsg('error', 'Please select a organization');
        }
    };
    const handleOnChangeSelect = selectedOptions => {
        console.log('selectedOptions')
        console.log('selectedOptions')
        console.log(selectedOptions)
        setSelectedObjOrganization(selectedOptions);
        
    };
    return (
        <Formik
            initialValues={objInitialValues}
            validationSchema={addAdminUserJWTSchema}
            onSubmit={onSubmitForm}
        >
            {({ errors, touched }) => (
                <Form>
                    <Row>
                        <Col md="4">
                            <FormGroup className={`form-group ${errors.name && touched.name && "text-danger"}`}>
                                <Label ><i className='bx bx-user'></i> Name</Label>
                                <Field
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="name"
                                    className={`form-control ${errors.name &&
                                        touched.name &&
                                        "is-invalid"}`}
                                />
                                
                                {errors.name && touched.name ? (
                                    <div className="invalid-feedback">{errors.name}</div>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup className={`form-group ${errors.email && touched.email && "text-danger"}`}>
                                <Label ><i className='bx bx-envelope'></i> Email Address</Label>
                                <Field
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    className={`form-control ${errors.email &&
                                        touched.email &&
                                        "is-invalid"}`}
                                />
                                
                                {errors.email && touched.email ? (
                                    <div className="invalid-feedback">{errors.email}</div>
                                ) : null}
                            </FormGroup>
                            
                            
                            
                            
                      
                            
                        </Col>
                        <Col md="4">
                            <FormGroup className={`form-group ${errors.password && touched.password && "text-danger"}`}>
                                <Label for="password"><i className='bx bx-lock-alt'></i> Password</Label>
                                <Field
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    className={`form-control ${errors.password &&
                                        touched.password &&
                                        "is-invalid"}`}
                                />
                                {errors.password && touched.password ? (
                                    <div className="invalid-feedback">{errors.password}</div>
                                ) : null}
                            </FormGroup>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup className={`form-group ${errors.organization_id && touched.organization_id && "text-danger"}`}>
                                <h6 className="text-bold-100">Select Organization</h6>
                                <Select
                                    components={animatedComponents}
                                    name="organization_id"
                                    options={formatedOrganizationDrpOptions}
                                    className="React"
                                    classNamePrefix="select"
                                    value={selectedObjOrganization}
                                    onChange={handleOnChangeSelect}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        isLoading === false ?
                        <Row>
                            <Col md="12" className="text-end">
                                <div className="add-listings-btn">
                                    <button type="submit">Create</button>
                                </div>
                            </Col>
                        </Row>
                        : 
                        <LoadingSpinner subClass="text-center" color="lightblueColor"/>
                    }
                </Form>
            )}
        </Formik>
    );
};

export default AddAppUsersForm;

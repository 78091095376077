
// User
export const REGISTER_USER = 'register_user';
export const ADMIN_LOGIN_USER = 'admin_login_user';
export const ADMIN_CREATE_NEW_ADMIN_USER = 'admin_create_new_admin_user';
export const FETCH_ADMIN_USERS = 'fetch_admin_users';
export const CLEAR_ADMIN_USERS = 'clear_admin_users';
export const DELETE_ADMIN_USER = 'delete_admin_user';
export const DELETE_ADMIN_USER_FROM_ADMIN_USER_LIST_TBL = 'delete_admin_user_from_admin_user_list_tbl';
export const UPDATE_ADMIN_USER = 'update_admin_user';
export const ADD_UPDATE_ADMIN_USER_IN_ADMIN_USER_LIST_TBL = 'add_update_admin_user_in_admin_user_list_tbl';

// Organizations
export const SAVE_ORGANIZATION = 'save_organization';
export const FETCH_ORGANIZATIONS = 'fetch_organizations';
export const CLEAR_ORGANIZATIONS = 'clear_organizations';
export const DELETE_ORGANIZATION = 'delete_organization';
export const DELETE_ORGANIZATION_FROM_ADMIN_ORGANIZATION_LIST_TBL = 'delete_organization_from_admin_organization_list_tbl';
export const UPDATE_ORGANIZATION = 'update_organization';
export const ADD_UPDATE_ORGANIZATION_IN_ORGANIZATION_LIST_TBL = 'add_update_organization_in_organization_list_tbl';
export const FETCH_ALL_ORGANIZATIONS = 'fetch_all_organizations';

export const SAVE_PERSONAL_SETTINGS = 'save_personal_settings';
export const SAVE_USER_PROFILE_PICTURE = 'save_user_profile_picture';
export const VERIFY_USER_EMAIL_ADDRESS = 'verify_user_email_address';
export const SET_USER_PASSWORD = 'set_user_password';
export const SEND_FORGOT_PASSWORD_EMAIL = 'send_forgot_password_email';
export const RESET_USER_PASSWORD_FROM_FORGOT_EMAIL = 'reset_user_password_from_forgot_email';
export const DELETE_USER_ACCOUNT = 'delete_user_account';

// SlideShow
export const FETCH_SLIDESHOW_BY_ID = 'fetch_slideshow_by_id';
export const FETCH_SLIDESHOW_ORDERS = 'fetch_slideshow_orders';
export const CLEAR_SLIDESHOW_ORDERS = 'clear_slideshow_orders';
export const UPDATE_SLIDESHOW_ORDER = 'update_slideshow_order';
export const ADD_UPDATE_SLIDESHOW_ORDER_IN_SLIDESHOW_ORDERS_LIST_TBL = 'add_update_slideshow_order_in_slideshow_orders_list_tbl';

// auth
export const ADMIN_AUTH_USER = 'admin_auth_user';
export const LOGOUT_ADMIN_USER = 'logout_admin_user';
export const UPDATE_ADMIN_AUTH_USER = 'update_admin_auth_user';
// User Subscriptions
export const BUY_BASIC_SUBSCRIPTION_PACKAGE = 'buy_basic_subscription_package';
export const CALCULATE_TOTAL_OF_THE_SUBSCRIPTION_PAYMENT = 'calculate_total_of_the_subscription_payment';
// Stripe
export const CREATE_PAYMENT_INTENT_OF_PROPERTY_SUBSCRIPTION = 'create_payment_intent_of_property_subscription';




// Google
export const GET_TOKEN_USING_CODE = 'get_token_using_code';
export const CHECK_GOOGLE_OAUTH_TOKEN = 'check_google_oauth_token';

// Admin App Users api
export const ADMIN_CREATE_NEW_APP_USER = 'admin_create_new_app_user';
export const FETCH_APP_USERS = 'fetch_app_users';
export const CLEAR_APP_USERS = 'clear_app_users';
export const DELETE_APP_USER = 'delete_app_user';
export const DELETE_APP_USER_FROM_APP_USER_LIST_TBL = 'delete_app_user_from_app_user_list_tbl';
export const ADD_UPDATE_APP_USER_IN_APP_USER_LIST_TBL = 'add_update_app_user_in_app_user_list_tbl';
export const UPDATE_APP_USER = 'update_app_user';

// Settings
export const FETCH_SETTINGS = 'fetch_settings';
import {
    ADMIN_AUTH_USER,
    LOGOUT_ADMIN_USER,
    UPDATE_ADMIN_AUTH_USER,
} from '../../actions/types';

export const adminUser = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_AUTH_USER: {
            return { ...state, objAdminUser: action.payload }
        }
        case LOGOUT_ADMIN_USER:{
            return {...state,objLogOutSuccess:action.payload,objAdminUser:{error: true,isAuth: false} }
        }
        case UPDATE_ADMIN_AUTH_USER:{
            return {...state, objAdminUser: action.payload}
        }
        
        default: {
            return state
        }
    }
}

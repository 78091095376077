import { combineReducers } from 'redux';
import adminAuthReducers from "./adminAuth/";
import organizationsReducers from "./organizations/";
import settings from "./settings/";
import appUserAuthReducers from "./appUserAuth/";

// stockPrices/
const rootReducer = combineReducers({
    adminAuth:adminAuthReducers,
    organizations:organizationsReducers,
    settings,
    appUserAuth:appUserAuthReducers,
});

export default rootReducer;
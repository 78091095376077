import React from 'react';
import { getCurrentYear } from '../utils/Helpers/Date/DateTimeHelpers';
const NoAuthLayoutFooter = () => {
    return (
        <>
            <div className='copyrights-area copyrights-area-no-auth'>
                <div className='text-center'>
                    <p>
                        Copyright {getCurrentYear()} cr LLC.
                    </p>
                    
                </div>
            </div>
        </>
    );
};

export default NoAuthLayoutFooter;